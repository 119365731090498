import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { WorksService } from '../../../../services/works.service';
import { ModalCommonComponent } from '../../../../components/common/modal-common/modal-common.component';
import { WksEntityParams, WksEntity, WksEntityImg, InputDirection, LocalAddress, FileToDisplay, LabelValue, WksWorksModel } from '../../../../models';
import { AuthService } from '../../../../services/auth.service';
import { SharingDataService } from '../../../../services/sharing-data.service';
import { WksUuidLinksModel } from '../../../../models/works/wks-common.model';
import { GlobalEventsManagerService } from '../../../../services//global-events-manager.service';

export interface WorkDoc {
  quoteWorkRef: string;
  docId: string;
  workId: string;
  wlReference: string;
}

@Component({
  selector: 'app-quotation-waiting',
  templateUrl: './quotation-waiting.component.html',
  styleUrls: ['./quotation-waiting.component.less']
})
export class QuotationWaitingComponent implements OnChanges {

  @Input() encodedReference!: string;
  @Input() statutCall: any;
  @Input() wksEntity: WksEntity;
  private readonly onDestroy = new Subject<void>();

  filesToDisplay: FileToDisplay[];
  readyToDisplayPdf: boolean;
  otherData: LabelValue[];
  workDoc: WorkDoc;

  constructor(private translate: TranslateService,
              private sharingDataService: SharingDataService,
              private worksService: WorksService,
              private authService: AuthService,
              private globalEventsManagerService: GlobalEventsManagerService,
              private dialog: MatDialog) {
              }
  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'encodedReference': {
            // tslint:disable-next-line:no-string-literal
            this.encodedReference = changes['encodedReference'].currentValue;
            break;
          }
          case 'statutCall': {
            // tslint:disable-next-line:no-string-literal
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }

        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.initAllBooleans();
    this.subscribeAll();
    this.otherData = [];
    this.loadQuotationValidation(this.wksEntity.stdEntity, this.encodedReference);
  }
  initAllBooleans(): void {

    this.readyToDisplayPdf = false;
  }
  subscribeAll(): void {
    this.globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // 'validateQuote
      let eventString: string;
      const typeEvent = typeof event;
      if (typeEvent === 'string')  {
        eventString = String(event);
        if (eventString !== undefined) {
          if (eventString.startsWith('validateQuote__')) {
            const fileRef = eventString.split('__')[1];
            this.validateQuote();
          } else if (eventString.startsWith('refuseQuote__')) {
            const fileRef = eventString.split('__')[1];
            this.refuseQuote();
          } else if (eventString.startsWith('approveQuote__')) {
            const fileRef = eventString.split('__')[1];
            this.approveQuote();
          } else if (eventString.startsWith('closeQuote__')) {
            const fileRef = eventString.split('__')[1];
            this.returnHome();
          }

        }
      } else if (event) {
         console.log(typeEvent);
      }
    });
  }
  loadQuotationValidation(entity: string, encodedReference: string): void {
    const titleBox = this.translate.instant('WORK_DOCUMENT.searchingDocumentTitle');
    let messageBox = this.translate.instant('WORK_DOCUMENT.searchingDocumentInProgress');
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'infoProgressWks', 'noAction', 'nodata');

    this.getQuotationValidation(entity, encodedReference)
    .then ((data: any) => {
      const waitingLink = data.body as WksUuidLinksModel;

      this.getWorkDoc(entity, waitingLink.wlLinkRef, waitingLink.wlReference)
      .then ((data1: any) => {
        this.dialog.closeAll();
      }) // end loadAnchorages
      .catch((err: any) => {
        console.log('getWorkDoc : ' + err.message);
        this.dialog.closeAll();
        // {identifierCur: $event }
        messageBox = this.translate.instant('WORK_DOCUMENT.searchingDocumentInerror', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
        return;
      });
    }) // end getQuotationValidation
    .catch((err: any) => {
      console.log('loadQuotationValidation : ' + err.message);
      this.dialog.closeAll();
      if (err.status === 599) {
        messageBox = this.translate.instant('WORK_DOCUMENT.searchingDocumentExpiredDeadline', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
      } else  {
        messageBox = this.translate.instant('WORK_DOCUMENT.searchingDocumentInerror', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
      }
      return;
    }); // end getQuotationValidation
  }
  getQuotationValidation(entity: string, encodedReference: string): any {

    return new Promise((resolve, reject) => {
      this.worksService.getQuotationValidation(entity, encodedReference)
      .subscribe(
        data => {
          const response = data ;

          resolve(response);
        }, err => {
          console.log('getQuotationValidation' + err.message);

          reject(err);
        }
      );
    });
  }
  getWorkDoc(entity: string, docId: string, wlReferenceArg: string): any {
    this.filesToDisplay = [];
    this.readyToDisplayPdf = false;
    return new Promise((resolve, reject) => {
      this.worksService.getWorkDoc(entity, docId)
      .subscribe(
        data => {
          const response = data.body ;
          const fileToDisplay: FileToDisplay = {
            fileName: response.workdocFilename,
            fileLegend: response.workdocLegend + ' / ' + response.workdocFilename,
            filePath: response.workdocPath + '/' + response.workdocFilename,
            fileRef: response.id + ':' + response.workdocFilename + ':' + response.id,
            dataImgLoaded: false,
            imageToShow: undefined
          };
          const workdocJsonTmp = response.workdocJson;
          const jsonData = JSON.parse(workdocJsonTmp);
          const jsonDocData = jsonData.docData;
          const jsonDocuments = jsonDocData.documents;
          const jsonDocument = jsonDocuments[0];
          const jsonDocumentTags = jsonDocument.tags;
          for (const jsonTag of jsonDocumentTags) {
            if (jsonTag.name === 'totalWT_inWords') {
              const labelValue: LabelValue = {
                label: 'totalWT_inWords',
                value: jsonTag.value
              };
              this.otherData.push(labelValue);
              break;
            }
          }
          this.workDoc =  {
            quoteWorkRef: jsonDocData.workRef,
            docId: response.id,
            workId: response.workId,
            wlReference: wlReferenceArg
          };

          this.filesToDisplay.push (fileToDisplay);
          this.readyToDisplayPdf = true;
          resolve(response);
        }, err => {
          console.log('getWorkDoc' + err.message);
          reject(err);
        }
      );
    });
  }
  validateQuote(): void {


  }
  refuseQuote(): void {
    const titleBox = this.translate.instant('WORK_DOCUMENT.confirmRefuseQuoteTitle');
    const messageBox = this.translate.instant('WORK_DOCUMENT.confirmRefuseQuoteLabel',
                              {workRef: this.workDoc.quoteWorkRef} );
    this.displayMessageBox(titleBox, messageBox, 'ERROR', 'confirmAction', 'confirmRefuseQuote', undefined );
  }
  approveQuote(): void {
    const titleBox = this.translate.instant('WORK_DOCUMENT.confirmApproveQuoteTitle');
    const messageBox = this.translate.instant('WORK_DOCUMENT.confirmApproveQuoteLabel',
                              {workRef: this.workDoc.quoteWorkRef});
    this.displayMessageBox(titleBox, messageBox, 'ERROR', 'confirmAction', 'confirmApproveQuote', undefined );
  }
  registerQuote( docstatut: string): void {
    this.dialog.closeAll();
    let titleBox = this.translate.instant('WORK_DOCUMENT.WritingDocumentTitle');
    let messageBox = this.translate.instant('WORK_DOCUMENT.WritingDocumentInProgress');
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'infoProgressWks', 'noAction', 'nodata');
    // this._wksCentralService.updateQuotation(this.userLangCur, docKeys[2], this.workToUpdate, 'validateNew');
    this.worksService.updateQuotation(this.authService.getUserLogged().entity,
                                this.authService.getUserLogged().userlang,
                                this.workDoc.wlReference,
                                this.workDoc.docId,
                                this.workDoc.workId,
                                docstatut)
    .subscribe(
      (data) => {
        const workReturn = data.body as WksWorksModel;
        this.dialog.closeAll();
        // WritingDocumentOK
        titleBox = this.translate.instant('WORK_DOCUMENT.WritingDocumentTitle');
        messageBox = this.translate.instant('WORK_DOCUMENT.WritingDocumentOK');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'confirmRegisterOK', 'nodata');
        // this.returnHome();
      },
      (err) => {
        console.log('registerQuote ' + JSON.stringify(this.workDoc) + ' KO ' + err.message );
        this.dialog.closeAll();
        titleBox = this.translate.instant('WORK_DOCUMENT.WritingDocumentTitle');
        messageBox = this.translate.instant('WORK_DOCUMENT.WritingDocumentKO');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'confirmRegisterKO', 'nodata');
        // this.returnHome();
      }
    );
  }
  displayMessageBox(titleBox: string, messageBox: string, messageTypeArg: string,
                    typeDialogArg: string, actionCur: string, dataArg: any): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    id: 1,
    title: titleBox,
    typeDialog: typeDialogArg,
    panelClass: 'stdTheme',
    contentMessage: messageBox,
    data1: '',
    data2: '',
    messageType: messageTypeArg

    };

    const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && actionCur === 'confirmChangeStatut') {

        }
        if (( data === 'okAction') && actionCur === 'confirmApproveQuote') {

          this.registerQuote('approved');
        }
        if (( data === 'okAction') && actionCur === 'confirmRefuseQuote') {

          this.registerQuote('refused');
        }
        // confirmRegisterOK
        if (actionCur === 'confirmRegisterOK') {
          this.returnHome();
        }
        // confirmRegisterOK
        if (actionCur === 'confirmRegisterKO') {
          this.returnHome();
        }
        if (actionCur === 'returnHome') {
          this.returnHome();
        }
        if (data === 'cancelAction') {
          this.dialog.closeAll();
        }
      });

  }
  returnHome(): void {
    this.readyToDisplayPdf = false;
    this.globalEventsManagerService.synchroEvents.next('returnHome');
  }
}
