<div *ngIf="isOkToDisplay" #refElement class="container-fluid stdTheme"
        [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px'}" >
    <div  >
        <form [formGroup]="jobForm"  style="margin: 0px; height: 100%;" >
            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px; margin-top: 10px">
                <div class="col-lg-6 col-xs-12 " style="padding-top: 0px;margin-top: 0px;">
                    <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-bottom: 0px!important">
                        <mat-label>{{ 'APPOINTMENT_DETAIL.JOB.CRITICALITY.criticalityState' | translate }}</mat-label>
                        <mat-select id="criticalityState" #criticalityState  formControlName="criticalityState" 
                                panelClass="mdiselectcontent" ngDefaultControl required 
                                (selectionChange)="choiceServices('criticalityState',criticalityState.value)" >
                            <mat-option class="selectOptionsTheme" *ngFor="let criticalityCur of criticalityList" 
                                [value]="criticalityCur.value"  >{{criticalityCur.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px">
                <div class="col-lg-6 col-xs-12 "  >
                    <div class="checkbox"  >
                        <label  >
                            <input type="checkbox" id="engineOverHaul"  #engineOverHaul class="inputTheme" formControlName="engineOverHaul"
                            (change)="choiceServices('engineOverHaul', engineOverHaul.checked)"
                            />
                            <span style="margin-top: 25px; margin-left: 0px; margin-left: 15px">{{ 'APPOINTMENT_DETAIL.JOB.ENGINE_OVERHAUL' | translate }}</span>
                        </label>
                    </div>
                    <div class="flex-row row " *ngIf="subEquipmentDisplay" style="margin-left: 0px;margin-right: 0px;margin-bottom: 10px">
                        <div class="col-lg-12 col-xs-12 " style="padding-right: 0px; padding-left: 0px;" >
                            <div *ngIf="subEquipmentsLoaded" >
                                <app-wks-appointment-equip
                                    [actionType]="subEquipmentNew ? 'add' : 'update'" 
                                    [equipType]="undefined" 
                                    [mainEquipmentArg]="undefined"
                                    [subEquipmentArg]="subEquipmentsCur"
                                    (dataOutMain)="updateMainEquip($event)" 
                                    (dataOutSub)="updateOverHaul($event)"
                                    (dataOutForm)="formEquipInput($event)"
                                    >
                                </app-wks-appointment-equip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px">
                <div class="col-lg-6 col-xs-12 "  >
                    <div class="checkbox"  >
                        <label  >
                            <input type="checkbox" id="maintenance"  #maintenance class="inputTheme" formControlName="maintenance"
                            (change)="choiceServices('maintenance', maintenance.checked)"
                            />
                            <span style="margin-top: 25px; margin-left: 0px; margin-left: 15px">{{ 'APPOINTMENT_DETAIL.JOB.MAINTENANCE' | translate }}</span>
                        </label>
                    </div>
                    
                </div>
            </div>
            <div class="flex-row row " *ngIf="jobEquipDisplay" style="margin-left: 0px;margin-right: 0px;margin-bottom: 10px">
                <div class="col-lg-12 col-xs-12 " style="padding-right: 0px; padding-left: 0px;" >
                    <div  >
                        <app-wks-appointment-equip-job
                            [mainEquipment]="workEquipments.mainEquipment"
                            [subEquipmentMotors]="workEquipments.subEquipments"
                            (dataOutJobEquip)="updateJobEquip($event)"
                            >
                        </app-wks-appointment-equip-job>
                    </div>
                </div>
            </div>
            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;">
                <div class="col-lg-6 col-xs-12 "  >
                    <div class="checkbox"  >
                        <label  >
                            <input type="checkbox" id="wintering"  #wintering class="inputTheme" formControlName="wintering"
                            (change)="choiceServices('wintering', wintering.checked)"
                            />
                            <span style="margin-top: 25px; margin-left: 0px; margin-left: 15px">{{ 'APPOINTMENT_DETAIL.JOB.WINTERING' | translate }}</span>
                        </label>
                    </div>
                </div>

            </div>
        </form>
    </div>

</div>
