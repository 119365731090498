import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { WorksService } from '../../../../services/works.service';
import { ModalCommonComponent } from '../../../../components/common/modal-common/modal-common.component';
import { WksEntityParams, WksEntity, WksEntityImg, InputDirection, LocalAddress, FileToDisplay, LabelValue, WksWorksModel } from '../../../../models';
import { AuthService } from '../../../../services/auth.service';
import { SharingDataService } from '../../../../services/sharing-data.service';
import { WksUuidLinksModel } from '../../../../models/works/wks-common.model';
import { GlobalEventsManagerService } from '../../../../services//global-events-manager.service';

@Component({
  selector: 'app-verifymail-waiting',
  templateUrl: './verifymail-waiting.component.html',
  styleUrls: ['./verifymail-waiting.component.less']
})
export class VerifymailWaitingComponent implements OnChanges {

  @Input() encodedReference!: string;
  @Input() statutCall: any;
  @Input() wksEntity: WksEntity;
  private readonly onDestroy = new Subject<void>();

  constructor(private translate: TranslateService,
              private sharingDataService: SharingDataService,
              private worksService: WorksService,
              private authService: AuthService,
              private globalEventsManagerService: GlobalEventsManagerService,
              private dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'encodedReference': {
            // tslint:disable-next-line:no-string-literal
            this.encodedReference = changes['encodedReference'].currentValue;
            break;
          }
          case 'statutCall': {
            // tslint:disable-next-line:no-string-literal
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }

        } // end switch
      } // end if
    }
    this.initData();
    this.verifyEmail(this.wksEntity.stdEntity, this.encodedReference);
  }
  initData(): void {
    this.initAllBooleans();
    this.subscribeAll();
  }
  initAllBooleans(): void {

  }
  subscribeAll(): void {

  }
  verifyEmail(entity: string, encodedLink: string): any  {
    const titleBox = this.translate.instant('VERIFY_MAIL.searchingLinkTitle');
    let messageBox = this.translate.instant('VERIFY_MAIL.searchingLinkInProgress');
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'infoProgressWks', 'noAction', 'nodata');
    this.getWaitingLink(entity, encodedLink)
    .then ((data: any) => {
      const waitingLink = data.body as WksUuidLinksModel;
      this.dialog.closeAll();
      messageBox = this.translate.instant('VERIFY_MAIL.searchingLinkOK', {});
      this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
    })
    .catch((err: any) => {
      console.log('verifyEmail : ' + err.message);
      this.dialog.closeAll();
      if (err.status === 404) {
        messageBox = this.translate.instant('VERIFY_MAIL.searchingLinkNotFounded', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
      } else if (err.status === 598) {
        messageBox = this.translate.instant('VERIFY_MAIL.searchingLinkAdressFailed', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
      } else if (err.status === 599) {
        messageBox = this.translate.instant('VERIFY_MAIL.searchingLinkExpiredDeadline', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
      } else  {
        messageBox = this.translate.instant('VERIFY_MAIL.searchingLinkInerror', {error: err.message});
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'returnHome', 'nodata');
      }
      return;
    }); // end verifyEmail
  }
  getWaitingLink(entity: string, encodedLink: string): any  {
    return new Promise((resolve, reject) => {
      this.worksService.verifyMail(entity, encodedLink)
      .subscribe(
        data => {
          const response = data ;
          resolve(response);
        }, err => {
          console.log('getWaitingLink' + err.message);
          reject(err);
        }
      );
    });
  }
displayMessageBox(titleBox: string, messageBox: string, messageTypeArg: string,
                  typeDialogArg: string, actionCur: string, dataArg: any): void {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: titleBox,
    typeDialog: typeDialogArg,
    panelClass: 'stdTheme',
    contentMessage: messageBox,
    data1: '',
    data2: '',
    messageType: messageTypeArg

  };

  const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

  dialogRef.afterClosed()
  .pipe(takeUntil(this.onDestroy))
  .subscribe(
    data => {
  // console.log('Dialog output:', data);

      if (actionCur === 'returnHome') {
      this.returnHome();
      }
      if (data === 'cancelAction') {
      this.dialog.closeAll();
      }
    });

  }
  returnHome(): void {
  this.globalEventsManagerService.synchroEvents.next('returnHome');
  }
}
