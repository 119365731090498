import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/services/appointment.service';
import { BrandParam, CountryLabel, CustomParam, FormFieldFmt, JobsAsked, LabelInt, LabelValue, MotorsConfigModel,
        ParentParam,
        PropulsionsModel, RegularServiceItem, ThrusterConfigModel, TrailersOptions, WksEntityImg, WksEquipmentModel,
        WorkEquipments} from 'src/app/models';
import { BrandWksModel, TypesLabel } from 'src/app/models/works/wks-common.model';
import { ModalCommonComponent } from '../../../../components/common/modal-common/modal-common.component';

import { AuthService } from 'src/app/services/auth.service';
import { SharingDataService } from 'src/app/services/sharing-data.service';
import { WorksService } from 'src/app/services/works.service';


@Component({
  selector: 'app-wks-appointment-jobs',
  templateUrl: './wks-appointment-jobs.component.html',
  styleUrls: ['./wks-appointment-jobs.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WksAppointmentJobsComponent implements OnChanges {

  @Input() workEquipments: WorkEquipments;
  @Input() isOkToDisplay: boolean;
  @Input() professionalUse: boolean;

  // @Output() dataOutSub = new EventEmitter<WksEquipmentModel>();
  @Output() dataOutForm = new EventEmitter<JobsAsked>();
  @Output() dataOutFormEquip = new EventEmitter<string>();

  private readonly onDestroy = new Subject<void>();

  jobForm: FormGroup;

  doEngineOverHaul: boolean;
  doMaintenance: boolean;
  doWintering: boolean;
  jobEquipDisplay: boolean;

  subEquipmentsCur: WksEquipmentModel[];

  subEquipmentsLoading: boolean;
  subEquipmentsLoaded: boolean;
  subEquipmentIsLoaded: boolean;
  subEquipmentNew: boolean;
  subEquipmentDisplay: boolean;

  subEquipType: string;

  regularServices: RegularServiceItem[];
  askedService: {
    label: string,
    type: string,
    hours: number
  };
  displayCountHours: boolean;

  hours: {
    min: number;
    max: number;
    step: number;
  };
  isProfessionnal: boolean;
  parentParam: ParentParam;

  jobsAsked: JobsAsked;

  inputJobForm: boolean;
  inputSubEquip: boolean;
  criticalityList: LabelValue[];

  constructor(private fb: FormBuilder,
              private sharingDataService: SharingDataService,
              private translateService: TranslateService,
              private appointmentService: AppointmentService,
              private worksService: WorksService,
              private authService: AuthService,
              private dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'workEquipments': {
            // tslint:disable-next-line:no-string-literal
            this.workEquipments = changes['workEquipments'].currentValue;
            break;
          }
          // professionalUse
          case 'professionalUse': {
            // tslint:disable-next-line:no-string-literal
            this.isProfessionnal = changes['professionalUse'].currentValue;
            break;
          }
          case 'isOkToDisplay': {
            // tslint:disable-next-line:no-string-literal
            this.isOkToDisplay = changes['isOkToDisplay'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    if (!this.isOkToDisplay || this.workEquipments === undefined || this.workEquipments.mainEquipment === undefined) {
      return;
    }
    this.initBooleans();
    this.initVariables();
    this.initGlobalsParameters();
    this.initParentParameters();
    this.buildJobForm();
  }
  initBooleans(): void {

    this.doEngineOverHaul = false;
    this.doMaintenance = false;
    this.doWintering = false;
    this.jobEquipDisplay = false;

    this.subEquipmentsLoading = false;
    this.subEquipmentsLoaded = false;
    this.subEquipmentIsLoaded = false;
    this.subEquipmentNew = false;
    this.subEquipmentDisplay = false;
    this.inputJobForm = false;
    this.inputSubEquip = false;
  }
  initGlobalsParameters(): void {

    this.regularServices = this.sharingDataService.getRegularServices();

    const listTmp = this.sharingDataService.getCriticality();
    this.criticalityList = [];
    for (const stateCur of listTmp) {
      const curLabel: LabelValue = {
        label: this.translateService.instant('APPOINTMENT_DETAIL.JOB.CRITICALITY.' + stateCur),
        value: stateCur
      };
      this.criticalityList.push(curLabel);
    }

    this.hours = {
      min: 0,
      max: 0,
      step: 0
    };
    this.loadSubEquipType();
    if (this.workEquipments.mainEquipment.id !== undefined) {
      this.loadSubEquipements();
    } else {
      this.addSubEquipmentsProcess();
    }
  }

  initVariables(): void {
    this.jobsAsked = {
      criticalityState: 'normal',
      regularService: {
        asked: false,
        subEquipments: undefined,
      },
      wintering: {
        asked: false,
        mainEquipment: undefined,
      },
      maintenance: {
        asked: false,
        jobEquips: []
      }
    };

  }
  initParentParameters(): void {
    this.parentParam = {
      equipPartOf: this.workEquipments.mainEquipment.id,
      entity: this.workEquipments.mainEquipment.stdEntity,
      ownerId: this.workEquipments.mainEquipment.ownerId,
      equipType: '',
      equipBoatPropulsion: this.workEquipments.mainEquipment.equipBoatPropulsion,
      equipBoatConfiguration: this.workEquipments.mainEquipment.equipBoatConfiguration,
      equipBoatManeuvring: this.workEquipments.mainEquipment.equipBoatManeuvring,
      commissioningDate: this.workEquipments.mainEquipment.commissioningDate,
    };
  }
  buildJobForm(): void {
    this.jobForm = this.fb.group({
      criticalityState: this.fb.control({value: ''}),
      engineOverHaul: this.fb.control({value: ''}),
      maintenance: this.fb.control({value: ''}),
      wintering: this.fb.control({value: ''}),
    });

    this.initJobFormValues();
  }
  initJobFormValues(): void {
    this.jobForm.patchValue({
      criticalityState: 'normal',
      engineOverHaul: false,
      maintenance: false,
      wintering: false
    });
  }
  loadSubEquipType(): void {
    const propulsionCur = this.workEquipments.mainEquipment.equipBoatPropulsion;
    const propulsionsList = this.sharingDataService.getPropulsionsList();
    for (const propulsionItem of propulsionsList) {
      if (propulsionItem.propulsionName === propulsionCur) {
        this.subEquipType = propulsionItem.equipType;
        break;
      }
    }
  }
  initHoursRange(brandName: string, serviceAsked: string, equipType: string): void {
    for (const regularServiceItem of this.regularServices) {
      if (regularServiceItem.brand === brandName) {
        for (const equipmentItem of regularServiceItem.equipmentsList) {
          if (equipmentItem.equipmentType === equipType) {
            for (const serviceItem of equipmentItem.servicesList) {
              if (serviceItem.serviceName === serviceAsked) {
                this.hours.min = serviceItem.hoursRange.min;
                this.hours.max  = serviceItem.hoursRange.max;
                if (this.isProfessionnal) {
                  this.hours.step = serviceItem.hoursRange.professionnalStep;
                } else {
                  this.hours.step = serviceItem.hoursRange.pleasurStep;
                }
              } // end test service name
            } // end loop service List
          } // end test equip type
        } // end loop equipment list
      } // end test brand name
    }
  }
  initFieldAskedServiceInput(): void{
    /*

    if (!this.displayCountHours) { // for setting validations
      this.serviceForm.get('askedService').setValidators(Validators.required);
    } else { // for clearing validations
      this.serviceForm.get('askedService').clearValidators();
    }
    this.serviceForm.get('askedService').updateValueAndValidity();
    */
  }
  choiceServices(field: string, value: any): void {

    switch (field) {
      case 'criticalityState':  {
        this.jobsAsked.criticalityState = value;
        this.ctrlInput();
        break;
      }
      case 'engineOverHaul': {
        this.doEngineOverHaul = value;
        if (this.doEngineOverHaul) {
          this.inputSubEquip = false;
          this.subEquipmentDisplay = true;
          this.updateOverHaul(this.subEquipmentsCur[0]);
        } else {
          // this.subEquipmentsCur = [];
          this.subEquipmentDisplay = false;
          // this.dataOutFormEquip.emit('subEquipInputKO');
          this.jobsAsked.regularService.asked = false;
          this.jobsAsked.regularService.subEquipments = undefined;
          this.inputSubEquip = true;
          this.ctrlInput();
        }
        break;
      }
      case 'maintenance': {
        this.doMaintenance = value;
        this.jobEquipDisplay = value;
        if (this.doMaintenance) {
          this.jobsAsked.maintenance.asked = true;
          this.jobsAsked.maintenance.jobEquips = [];
          this.inputJobForm = false;
        } else {
          this.jobsAsked.maintenance.asked = false;
          this.jobsAsked.maintenance.jobEquips = undefined;
          this.inputJobForm = true;
        }
        this.ctrlInput();
        break;
      }
      case 'wintering': {
        this.doWintering = value;
        if (this.doWintering) {
          this.jobsAsked.wintering.asked = true;
          this.jobsAsked.wintering.mainEquipment = this.workEquipments.mainEquipment;
          if (!this.doEngineOverHaul && !this.doMaintenance) {
            this.inputJobForm = true;
            this.inputSubEquip = true;
          }
        } else {
          this.jobsAsked.wintering.asked = false;
          this.jobsAsked.wintering.mainEquipment = undefined;
        }
        this.ctrlInput();
        break;
      }
    }
  }
  formEquipInput(formStatut: string ): void {

    const isMaintenance = this.jobForm.controls.maintenance.value;

    if (formStatut === 'subEquipInputOK') {
      this.inputSubEquip = true;
      if (!isMaintenance) {
        this.inputJobForm = true;
      }
    } else {
      this.inputSubEquip = false;
    }
    this.ctrlInput();
  }
  ctrlInput(): void {
    if (!this.doEngineOverHaul && !this.doMaintenance && !this.doWintering) {
      this.inputJobForm = false;
    }
    this.dataOutForm.emit(this.jobsAsked);
    if (this.inputJobForm && this.inputSubEquip) {
      this.dataOutFormEquip.emit('subEquipInputOK');
    } else {
      this.dataOutFormEquip.emit('subEquipInputKO');
    }
  }
  updateMainEquip(equipMain: WksEquipmentModel ): void {

  }
  /*
  updateSubEquip(equipSub: WksEquipmentModel ): void {
    this.workEquipments.subEquipments[0] = equipSub;
    this.dataOutSub.emit(equipSub);
  }
    */
  updateOverHaul(equipSub: WksEquipmentModel ): void {
    this.jobsAsked.regularService.asked = true;
    this.jobsAsked.regularService.subEquipments = [];
    this.jobsAsked.regularService.subEquipments[0] = equipSub;
    for (const subEquip of this.subEquipmentsCur) {
      if (subEquip.id === undefined) {
        subEquip.equipBrand = equipSub.equipBrand;
        subEquip.equipModel = equipSub.equipModelInput;
      }
      subEquip.equipYear = equipSub.equipYear;
      subEquip.equipHours = equipSub.equipHours;
    }
    this.workEquipments.subEquipments = this.subEquipmentsCur;

    this.ctrlInput();
  }
  updateJobEquip(event: any ): void {
    this.jobsAsked.maintenance.asked = true;
    this.jobsAsked.maintenance.jobEquips = event.jobsEquip;
    this.inputJobForm = event.inputOK;
    const isEngineOverHaul = this.jobForm.controls.engineOverHaul.value;
    if (!isEngineOverHaul) {
      this.inputSubEquip = true;
    }
    this.ctrlInput();
  }
  loadSubEquipements(): boolean {

    this.subEquipmentsLoading = true;
    this.subEquipmentsCur = [];
    this.subEquipmentDisplay = false;
    // this.getWksEquipmentByPartOfandEquipType(this.mainEquipmentCur.id, this.equipTypeSelected.equipType)
    this.getWksEquipmentByPartOfandEquipType(this.workEquipments.mainEquipment.id, this.subEquipType)
    .then ((response: any) => {
      // si équipements existants
      this.subEquipmentsLoading = false;
      this.subEquipmentsLoaded = true;

      this.subEquipmentsCur =   response  as WksEquipmentModel[];
      // this.subEquipmentDisplay = true;
      this.subEquipmentNew = false;
      let idxItem = 0;
      for (const equipItem of this.subEquipmentsCur) {
        equipItem.subEquipmentNew = false;
        equipItem.equipNumber = idxItem;
        equipItem.equipModelInput = equipItem.equipModel;
        idxItem++;
      }

      this.workEquipments.subEquipments = this.subEquipmentsCur;

      return true;
    })
    .catch ((error: number) => {
      this.subEquipmentsLoading = false;
      // si aucun équipement pour le client -- > nouvel équipement (addEquipmentProcess)
      if (error === 404) {

        if (this.subEquipType === 'outboard engine' || this.subEquipType === 'inboard engine') {
          this.addSubEquipmentsProcess();
          this.subEquipmentsLoaded = true;
        } else {
        const titleBox = this.translateService.instant('APPOINTMENT_DETAIL.TITLE_EQUIPMENT_BOX');
        const messageBox = this.translateService.instant('APPOINTMENT_DETAIL.EQUIPMENT_SUB_NOT_FOUND');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'noSubEquipmentFound', undefined, undefined );
        }
      }
      return false;
    });
    return false;
  }
  addSubEquipmentsProcess(): void {
    /*
    if (this.subEquipmentsCur === undefined) {
      this.subEquipmentsCur = [];
    }
    if (this.subEquipType === 'outboard engine') {
      const motorsConfig = this.sharingDataService.getMotorsconfigList() as MotorsConfigModel[];
      const mainConfig = this.workEquipments.mainEquipment.equipBoatConfiguration;
      let motorConfigCur: MotorsConfigModel;
      for (const motorConfigItem of motorsConfig ) {
        if (mainConfig === motorConfigItem.configName) {
          motorConfigCur = motorConfigItem;
          break;
        }
      }
      if (motorConfigCur === undefined) {
        return;
      }
      let idxItem = 0 ;
      for (const positionItem of motorConfigCur.positions)  {
        const labelName = 'WORK_EQUIPMENT.' + positionItem;
        const equipNameItem = this.translateService.instant(labelName);
        const itemEmpty: WksEquipmentModel = {
          stdEntity: this.parentParam.entity,
          subEquipmentNew: true,
          equipPartOf: this.parentParam.equipPartOf,
          ownerId: this.parentParam.ownerId,
          equipType: this.subEquipType,
          equipName: equipNameItem,
          equipNumber: idxItem,
          equipCountry: this.workEquipments.mainEquipment.equipCountry
        };
        this.subEquipmentsCur.push(itemEmpty);
        idxItem++;
      }
      this.workEquipments.subEquipments = this.subEquipmentsCur;
    }
      */
    this.subEquipmentsCur = this.appointmentService.generateSubEquipmentsMotors(this.subEquipType, this.workEquipments.mainEquipment);
    this.workEquipments.subEquipments = this.subEquipmentsCur;
    this.subEquipmentsLoaded = true;
  }
  getWksEquipmentByPartOfandEquipType(equipPartOf: string, equipType: string ): any {

    return new Promise((resolve, reject) => {
      this.worksService.getWksEquipmentListBoat(this.workEquipments.mainEquipment.stdEntity, equipPartOf, equipType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body as WksEquipmentModel[]);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  /*
  addSubEquipment(): void {
    if (this.subEquipmentsCur === undefined) {
      this.subEquipmentsCur = [];
    }
    const nbSubEquip = this.subEquipmentsCur.length;
    const itemEmpty: WksEquipmentModel = {
      stdEntity: this.workEquipments.mainEquipment.stdEntity,
      subEquipmentNew: true,
      equipPartOf: this.parentParam.equipPartOf,
      ownerId: this.parentParam.ownerId,
      equipType: this.subEquipType,
      equipName: this.translateService.instant('APPOINTMENT_DETAIL.' + 'New engine'),
      equipNumber: nbSubEquip
    };
    this.subEquipmentsCur.push(itemEmpty);
    this.subEquipmentDisplay = true;
    this.workEquipments.subEquipments = this.subEquipmentsCur;
  }
    */
  displayMessageBox(titleBoxArg: any, messageBoxArg: any, messageTypeArg: string, typeDialogArg: string,
                    actionCurArg: string, data1Arg: string, data2Arg: string): void {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: titleBoxArg,
    typeDialog: typeDialogArg,
    panelClass: 'stdTheme',
    contentMessage: messageBoxArg,
    data1: data1Arg,
    data2: data2Arg,
    messageType: messageTypeArg,
    actionCur: actionCurArg
  };

  const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

  dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (actionCurArg === 'htmlMessage' ) {
          // this.returnHome();
        }
        if (actionCurArg === 'noBoatFound' ) {

        }
    });

  }

}
