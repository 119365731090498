<div class="container-fluid" style="width: 100%;">
    <div *ngIf="!okToDisplay && isWaiting">
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="spinner-container  stdTheme col"  fxLayout="row" fxLayoutAlign="space-around center">
                <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
            </div>
        </div>
        <div class="flex-row row  stdTheme" style="width: 100%;">
            <div class="stdTheme col"  style="text-align: center;">
                <span > 
                    {{ 'APPOINTMENT_DETAIL.LOADING' | translate }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="okToDisplay"class="stdTheme" style="margin-top: 5px;margin-left: 10px;margin-right: 10px;">
        <h4  style="text-align: center;">{{customerName}}</h4>
        <mat-horizontal-stepper class="stdTheme" #stepper linear (selectionChange)="setIndexStepper($event)" (click)="triggerClickStepper()">
            <mat-step >
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'APPOINTMENT_DETAIL.CUSTOMER_IDENTITY' | translate }}</span>
                </ng-template>
                <ng-container [ngTemplateOutlet]="customerFormTemplate" [ngTemplateOutletContext]="{valueArg: owner}" ></ng-container>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button (click)="returnHome()" >
                        <mat-icon>home</mat-icon>
                        {{ 'COMMUN.BUTTON.HOME' | translate }}
                    </button>
                    <button mat-raised-button matStepperNext (click)="loadBoatsList(mainItemType, true)">
                        <mat-icon>navigate_next</mat-icon>
                        {{ 'COMMUN.BUTTON.NEXT' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step  [completed]="isEquipmentCompleted">
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'APPOINTMENT_DETAIL.boat' | translate }}</span>
                </ng-template>
                <ng-container  [ngTemplateOutlet]="mainEquipmentFormTemplate" [ngTemplateOutletContext]="{valueArg: owner}" ></ng-container>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button matStepperPrevious>
                        <mat-icon>navigate_before</mat-icon>
                        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                    </button>
                    <button mat-raised-button matStepperNext *ngIf="isEquipmentCompleted">
                        <mat-icon>navigate_next</mat-icon>
                        {{ 'COMMUN.BUTTON.NEXT' | translate }}
                    </button>
                </div>
            </mat-step>

            <mat-step  [completed]="isServiceCompleted" [editable]="isEquipmentCompleted">
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'APPOINTMENT_DETAIL.SERVICE' | translate }}</span>
                </ng-template>
                <ng-container  [ngTemplateOutlet]="serviceFormTemplate"  ></ng-container>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button matStepperPrevious>
                        <mat-icon>navigate_before</mat-icon>
                        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                    </button>
                    <button mat-raised-button matStepperNext *ngIf="isServiceCompleted">
                        <mat-icon>navigate_next</mat-icon>
                        {{ 'COMMUN.BUTTON.NEXT' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step [editable]="isServiceCompleted">
                <ng-template matStepLabel>
                    <span class="stdTheme">{{ 'ADDRESS_BLOCK.DONE' | translate }}</span>
                </ng-template>
                <p>{{ 'ADDRESS_BLOCK.DONE_LABEL' | translate }}</p>
                <div style="display: flex; justify-content: space-between;margin-top: 5px;">
                    <button mat-raised-button matStepperPrevious>
                        <mat-icon>navigate_before</mat-icon>
                        {{ 'COMMUN.BUTTON.BEFORE' | translate }}
                    </button>
                    <button mat-raised-button (click)="submitAppointment()" [disabled]="submitDone">
                        {{ 'COMMUN.BUTTON.SUBMIT' | translate }}
                    </button>
                </div>
                <div *ngIf="submitDone && !workIsSave">
                    <div class="flex-row row  stdTheme" style="width: 100%;">
                        <div class="spinner-container  stdTheme col"  fxLayout="row" fxLayoutAlign="space-around center">
                            <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
                        </div>
                    </div>
                    <div class="flex-row row  stdTheme" style="width: 100%;">
                        <div class="stdTheme col"  style="text-align: center;">
                            <span > 
                                {{ 'APPOINTMENT_DETAIL.REQUEST_VALIDATION' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>           
    </div>
</div>
<ng-template let-value="valueArg" #customerFormTemplate>
    <div class="flex-row row"  style="margin: 0px; margin-top: 20px;">
        <app-address-block 
            [config]="configAddress" 
            [dataIn]="addressCur" 
            (dataOut)="dataOutAddress($event)">
        </app-address-block>
    </div>
</ng-template>


<ng-template let-value="valueArg" #mainEquipmentFormTemplate>
    <div *ngIf="equipmentChoiceDisplay" class="flex-row row stdTheme" style="margin: 0px;margin-bottom: 10px;">

        <form [formGroup]="equipmentChoiceForm" style="width: 100%">
            <div class="col-lg-6 col-xs-12">
                <!--
                <div class="flex-row row stdTheme">
                    <div class="col-lg-4" style="padding-top: 20px;">
                        <label class="stdTheme ">{{ 'APPOINTMENT_DETAIL.EQUIPMENT_TYPE' | translate }}</label>
                    </div>
                    <div class="col-lg-6 col-xs-12" style="padding-top: 10px;">
                        <mat-select id="equipType" placeholder="{{ 'APPOINTMENT_DETAIL.EQUIPMENT_CHOICE_TYPE' | translate }}"
                            panelClass="mdiselectcontent" #equipTypeSelect ngDefaultControl
                            formControlName="equipType"
                            (selectionChange)="onChangeEquipType(equipTypeSelect.value)">
                            <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of equipTypeList"
                                [value]="equipTypeCur">{{equipTypeCur.labelType}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                -->
                <div *ngIf="choiceBoatDisplay" style="width: 100%" >
                    <div class="flex-row row stdTheme"  id="boatsSelect" style="padding-top: 10px;">
                        <div class="col-lg-4" style="padding-top: 20px;"></div>
                        <div class="col-lg-6 col-xs-12">
                            <div *ngIf="mainEquipmentsLoading">
                                <span class="blink_text">
                                    {{ 'APPOINTMENT_DETAIL.LOADING_BOATS' | translate }}
                                </span>
                            </div>
                            <div *ngIf="mainEquipmentsLoaded">
                                <mat-select id="workBoat" #workBoat panelClass="mdiselectcontent" ngDefaultControl
                                    placeholder=" {{ 'APPOINTMENT_DETAIL.CHOICE_BOATS' | translate }}"
                                    (selectionChange)="onChangeworkBoat(workBoat.value)" formControlName="workBoat"
                                    >
                                    <mat-option class="selectOptionsTheme" *ngFor="let workBoatCur of boatsList"
                                        [value]="workBoatCur"> {{workBoatCur.equipName}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row row stdTheme"  id="boatsSelect" >
                        <div class="col-lg-4" style="padding-top: 20px;"></div>
                        <div class="col-lg-6 col-xs-12" *ngIf="mainEquipmentsLoaded" style="padding-top: 10px;">
                            <button type="button" class="stdTheme" style="border: 0 !important;" (click)="addMainEquipment()" id="CREATE_EQUIPMENT">
                                <i class="fa fa-plus"  aria-hidden="true"></i>
                                {{ 'APPOINTMENT_DETAIL.CREATE_FIRSTMAIN_EQUIPMENT' | translate }}
                            </button>
                        </div>
                    </div>
                </div>    
            </div>
        </form>
    </div>
    <div *ngIf="mainEquipmentDisplay" >
        <app-wks-appointment-equip
            [actionType]="mainEquipmentNew ? 'add' : 'update'" 
            [equipType]="mainItemType" 
            [mainEquipmentArg]="mainEquipmentCur"
            [subEquipmentArg]="undefined"
            (dataOutMain)="updateMainEquip($event)" 
            (dataOutForm)="formEquipInput($event)"
            >
        </app-wks-appointment-equip>
    </div>
    
</ng-template>
<ng-template #serviceFormTemplate>
    <app-wks-appointment-jobs
        [isOkToDisplay]="isEquipmentCompleted"
        [workEquipments]="workEquipments"
        [professionalUse]="isProfessionnal"
        (dataOutForm)="formJobInput($event)"
        (dataOutFormEquip)="formEquipInput($event)"
    >
</app-wks-appointment-jobs>
</ng-template>
