/*
https://stackblitz.com/edit/angular-webcam-snapshot-taken?file=src%2Fapp%2Fwebcam-snapshot%2Fwebcam-snapshot.component.ts
*/
import { AfterViewInit, Component, ElementRef, ViewChild  } from '@angular/core';
import { WebcamUtil } from '../webcam-snapshot-v2/util/webcam.util';

@Component({
  selector: 'app-webcam-snapshot-v1',
  templateUrl: './webcam-snapshot-v1.component.html',
  styleUrls: ['./webcam-snapshot-v1.component.less']
})
export class WebcamSnapshotV1Component implements AfterViewInit {
  // WIDTH = 640;
  // HEIGHT = 480;

  // -------------------------------------------------------- Start reprise V2 : ngx-webcam */
  private  DEFAULT_VIDEO_OPTIONS: MediaTrackConstraints = {facingMode: 'environment'};
  private  DEFAULT_IMAGE_TYPE = 'image/jpeg';
  private  DEFAULT_IMAGE_QUALITY = 0.92;
  /** Flag to enable/disable camera switch. If enabled, a switch icon will be displayed if multiple cameras were found */
  public allowCameraSwitch = true;
  /** Defines the max width of the webcam area in px */
  public width = 640;
  /** Defines the max height of the webcam area in px */
  public height = 480;
  /** available video devices */
  public availableVideoInputs: MediaDeviceInfo[] = [];
  public videoOptions: MediaTrackConstraints = this.DEFAULT_VIDEO_OPTIONS;
  /** Indicates whether the video device is ready to be switched */
  public videoInitialized = false;
  /** MediaStream object in use for streaming UserMedia data */
  private mediaStream: MediaStream = null;
  /** width and height of the active video stream */
  private activeVideoSettings: MediaTrackSettings = null;
  /** Index of active video in availableVideoInputs */
  private activeVideoInputIndex = -1;
  @ViewChild('video', { static: true }) private video: ElementRef<HTMLVideoElement>;
  /** Canvas for Video Snapshots */
  @ViewChild('canvas', { static: true }) private canvas: ElementRef<HTMLCanvasElement>;
  public multipleWebcamsAvailable = false;
  // --------------------------------------------------------- End reprise V2 : ngx-webcam

  // @ViewChild('sidenav', {static: false}) mSidenav: any;
  // @ViewChild('video' , {static: false}) public video: ElementRef;
  // @ViewChild('canvas' , {static: false}) public canvas: ElementRef;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;


  // tslint:disable-next-line:typedef
  //   async ngAfterViewInit() {
  //   await this.setupDevices();
  ngAfterViewInit(): void {
    // await this.setupDevices();
    // ----------------------------------------------------- start V2 ngx-webcam
    this.detectAvailableDevices()
    .then(() => {
      // start video
      this.switchToVideoInput(null);
    })
    .catch((err: string) => {
      // this.initError.next(<WebcamInitError>{message: err});
      // fallback: still try to load webcam, even if device enumeration failed
      this.switchToVideoInput(null);
    });
    // ------------------------------------------------------- end V2 ngx-webcam
  }
  // ----------------------------------------------------- start V2 ngx-webcam
  /**
   * Switches the camera-view to the specified video device
   */
  public switchToVideoInput(deviceId: string): void {
    this.videoInitialized = false;
    this.stopMediaTracks();
    this.initWebcam(deviceId, this.videoOptions);
  }
  /*
  * Reads available input devices
  */
  private detectAvailableDevices(): Promise<MediaDeviceInfo[]> {
    return new Promise((resolve, reject) => {
      WebcamUtil.getAvailableVideoInputs()
        .then((devices: MediaDeviceInfo[]) => {
          this.availableVideoInputs = devices;
          this.multipleWebcamsAvailable = (devices.length > 1 ? true : false);
        })
        .catch(err => {
          this.availableVideoInputs = [];
          reject(err);
        });
    });
  }
  /*
   * Stops all active media tracks.
   * This prevents the webcam from being indicated as active,
   * even if it is no longer used by this component.
   */
    private stopMediaTracks(): void {
      if (this.mediaStream && this.mediaStream.getTracks) {
        // pause video to prevent mobile browser freezes
        this.video.nativeElement.pause();

        // getTracks() returns all media tracks (video+audio)
        this.mediaStream.getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());
      }
    }
  /**
   * Init webcam live view
   */
  private initWebcam(deviceId: string, userVideoTrackConstraints: MediaTrackConstraints): void {
    const videoCur = this.video.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      // merge deviceId -> userVideoTrackConstraints
      const videoTrackConstraints = this.getMediaConstraintsForDevice(deviceId, userVideoTrackConstraints);

      navigator.mediaDevices.getUserMedia({video: videoTrackConstraints} as MediaStreamConstraints)
        .then((stream: MediaStream) => {
          this.mediaStream = stream;
          videoCur.srcObject = stream;
          videoCur.play();

          this.activeVideoSettings = stream.getVideoTracks()[0].getSettings();
          const activeDeviceId: string = this.getDeviceIdFromMediaStreamTrack(stream.getVideoTracks()[0]);
          // JLG pas utile dans notre utilisation
          // this.cameraSwitched.next(activeDeviceId);

          // Initial detect may run before user gave permissions, returning no deviceIds. This prevents later camera switches. (#47)
          // Run detect once again within getUserMedia callback, to make sure this time we have permissions and get deviceIds.
          this.detectAvailableDevices()
            .then(() => {
              /*
              this.activeVideoInputIndex = activeDeviceId ? this.availableVideoInputs
                .findIndex((mediaDeviceInfo: MediaDeviceInfo) => mediaDeviceInfo.deviceId === activeDeviceId) : -1;
                */
              this.videoInitialized = true;
            })
            .catch(() => {
              // this.activeVideoInputIndex = -1;
              this.videoInitialized = true;
            });
        })
        .catch((err: DOMException) => {
           // JLG pas utile dans notre utilisation
          // this.initError.next(<WebcamInitError>{message: err.message, mediaStreamError: err});
          console.log('initWebcam catch error navigator : ' + JSON.stringify(err));
        });
    } else {
      // JLG pas utile dans notre utilisation
      // this.initError.next(<WebcamInitError>{message: 'Cannot read UserMedia from MediaDevices.'});
      console.log('initWebcam catch error if  : ' + 'Cannot read UserMedia from MediaDevices.');
    }
  }
  /*
   * Get MediaTrackConstraints to request streaming the given device
   * @param deviceId
   * @param baseMediaTrackConstraints base constraints to merge deviceId-constraint into
   * @returns
   */
  private getMediaConstraintsForDevice(deviceId: string, baseMediaTrackConstraints: MediaTrackConstraints): MediaTrackConstraints {
    const result: MediaTrackConstraints = baseMediaTrackConstraints ? baseMediaTrackConstraints : this.DEFAULT_VIDEO_OPTIONS;
    if (deviceId) {
      result.deviceId = {exact: deviceId};
    }

    return result;
  }
  /*
   * Tries to harvest the deviceId from the given mediaStreamTrack object.
   * Browsers populate this object differently; this method tries some different approaches
   * to read the id.
   * @param mediaStreamTrack
   * @returns deviceId if found in the mediaStreamTrack
   */
    private getDeviceIdFromMediaStreamTrack(mediaStreamTrack: MediaStreamTrack): string {
      if (mediaStreamTrack.getSettings && mediaStreamTrack.getSettings() && mediaStreamTrack.getSettings().deviceId) {
        return mediaStreamTrack.getSettings().deviceId;
      } else if (mediaStreamTrack.getConstraints && mediaStreamTrack.getConstraints() && mediaStreamTrack.getConstraints().deviceId) {
        const deviceIdObj: ConstrainDOMString = mediaStreamTrack.getConstraints().deviceId;
        return this.getValueFromConstrainDOMString(deviceIdObj);
      } else {
        return null;
      }
    }
  /*
   * Extracts the value from the given ConstrainDOMString
   * @param constrainDOMString
   */
  private  getValueFromConstrainDOMString(constrainDOMString: ConstrainDOMString): string {
    if (constrainDOMString) {
      if (constrainDOMString instanceof String) {
        return String(constrainDOMString);
      } else if (Array.isArray(constrainDOMString) && Array(constrainDOMString).length > 0) {
        return String(constrainDOMString[0]);
      } else if (typeof constrainDOMString === 'object') {
        if (constrainDOMString.toString() === 'exact') {
          return 'exact';
        } else if (constrainDOMString.toString() === 'ideal') {
          return 'ideal';
        }
        /*
        if (constrainDOMString['exact']) {
          return String(constrainDOMString['exact']);
        } else if (constrainDOMString['ideal']) {
          return String(constrainDOMString['ideal']);
        }
          */
      }
    }

    return null;
  }
   /*
   * Returns the video aspect ratio of the active video stream
   */
   private getVideoAspectRatio(): number {
    // calculate ratio from video element dimensions if present
    const videoElement = this.video.nativeElement;
    if (videoElement.videoWidth && videoElement.videoWidth > 0 &&
      videoElement.videoHeight && videoElement.videoHeight > 0) {

      return videoElement.videoWidth / videoElement.videoHeight;
    }

    // nothing present - calculate ratio based on width/height params
    return this.width / this.height;
  }
  /**
   * Event-handler for video resize event.
   * Triggers Angular change detection so that new video dimensions get applied
   */
  public videoResize(): void {
    // here to trigger Angular change detection
  }

  public getVideoWidth(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.width, this.height * videoRatio);
  }

  public getVideoHeight(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.height, this.width / videoRatio);
  }
  /*
   * Switches to the next/previous video device
   * @param forward
   */
    public rotateVideoInput(forward: boolean): void {
      if (this.availableVideoInputs && this.availableVideoInputs.length > 1) {
        /*
        const increment: number = forward ? 1 : (this.availableVideoInputs.length - 1);
        const nextInputIndex = (this.activeVideoInputIndex + increment) % this.availableVideoInputs.length;
        */
        let nextInputIndex = this.activeVideoInputIndex;
        if (this.activeVideoInputIndex < this.availableVideoInputs.length - 1) {
          nextInputIndex++;
        } else {
          nextInputIndex = 0;
        }
        this.switchToVideoInput(this.availableVideoInputs[nextInputIndex].deviceId);
      }
    }

  // ------------------------------------------------------- end V2 ngx-webcam
  // tslint:disable-next-line:typedef
  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  capture(): void {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures.push(this.canvas.nativeElement.toDataURL('image/png'));
    this.isCaptured = true;
  }

  resetCurrent(): void {
    this.isCaptured = false;
  }

  setPhoto(idx: number): void {
    this.isCaptured = true;
    const image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }

  drawImageToCanvas(image: any): void {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.getVideoWidth(), this.getVideoHeight());
  }
}
