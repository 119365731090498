import { Component, OnInit,  Input, Output, EventEmitter, ElementRef, OnChanges,
              SimpleChanges, AfterContentInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray} from '@angular/forms';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CommonMethods } from '../tools/commonMethods';
import { CountryDef, CountryLabel, PhoneInput, StatesLabel, EmailInput, ElemAddr, GoogleMaps, LatLng, LabelValue  } from '../../../models';
import { GlobalEventsManagerService } from '../../../services/global-events-manager.service';
// AuthService
import { AuthService } from '../../../services/auth.service';
import { SharingDataService } from '../../../services/sharing-data.service';
import { EventBroadcastService } from '../../../services/event-broadcast.service';
import { environment } from '../../../../environments/environment';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AddressBlockComponent implements OnChanges, AfterContentInit {

  @Input() dataIn: any;
  @Input() config: any;
 //  @Input() markChange: boolean;
  @Output() dataOut = new EventEmitter<any>();
  private readonly onDestroy = new Subject<void>();
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;


  // config
  langCur: string;
  testAlone: string;
  countryDefault: string;
  address: boolean;
  telephone: boolean;
  email: boolean;
  contact: boolean;
  corporate: boolean;
  uiType: string ;
  dataOrig: any;
  countryCur: CountryDef;
  countryKeyToLoad: string;
  countriesCacheList: CountryLabel[];
  countriesFilteredList: CountryLabel[];
  statesCacheList: StatesLabel[];
  statesFilteredList: StatesLabel[];
  dataCur: any;

  countriesCount: number;
  countryIndex: number;
  countryLoaded: string;
  addressForm: FormGroup;
  identifierBlockFg: FormGroup;
  selectAppsFg: FormGroup;
  addressBlockFg: FormGroup;
  contactBlockFg: FormGroup;
  latLngBlockFg: FormGroup;
  corporateBlockFg: FormGroup;
  // addressElems: FormArray;
  countrySelect: FormControl;
  selectApps: FormControl;
  filterCountry: FormControl;
  telephoneListFg: FormArray;
  emailsListFg: FormArray;
  phonesList: PhoneInput[];
  emailsList: EmailInput[];
  civilityList: any[];

  // listAdressBlock: any[];
  rowArray: any[];
  isFormLoaded: boolean;
  isAddressLoaded: boolean;
  isPhonesLoaded: boolean;
  isEmailsLoaded: boolean;
  isDataIn: boolean;
  isReadOnly: boolean;

  phoneTypeList = ['phoneDesk', 'phoneCell', 'phoneCellWhatsApp', 'phoneHome', 'phoneOther'];
  // emailRegexCur = new RegExp(/(^[a-z]+[a-z0-9._]+)(@)([a-z]+\.[a-z.]{2,5}$)/);
  // defaultEmailFmt = '(^[a-z]+[a-z0-9_\.]+)(@)([a-z]+)([\.][a-z]{2,5}$)';
  emailAttr = environment.emailAttr;
  isDomesticCountry: boolean;
  phoneFormat: string;
  phonePrefix: string;
  phoneLengthMax: string;

  identifier: ElemAddr;

  identifierInput: boolean;
  identifierMandatory: boolean;
  appList =  ['Email', 'SMS', 'WhatsApp', 'XMPP'];

  isProfessionnal: boolean;
  inputLatLng: boolean;

  deptList: LabelValue[];

  // maps
  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modalTask',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
  };
  googleMaps: GoogleMaps;
  isGeoLocation: boolean;
  mapAddress: Map<string, string>;

  constructor(private modalService: BsModalService,
              private translateService: TranslateService,
              private fb: FormBuilder,
              private el: ElementRef,
              private authService: AuthService,
              private sharingDataService: SharingDataService,
              private coordinatesService: CoordinatesService,
              private globalEventsManagerService: GlobalEventsManagerService,
              private eventBroadcastService: EventBroadcastService,
              ) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
           // actionType
          case 'config': {
            this.config = changes.config.currentValue;
            break;
          } // end case
          // dataIn
          case 'dataIn': {
            this.dataIn = changes.dataIn.currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initSubscribes(): void {
    this.globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // closeMapModalLatLng__
      let eventString: string;
      const typeEvent = typeof event;
      if (typeEvent === 'string')  {
        eventString = String(event);
        if (eventString.startsWith('closeMapModalLatLng__')) {
          this.closeModal();
          const latLng = JSON.parse(eventString.split('__')[1]) as LatLng;
          this.displayLatLng(latLng);
        }
      }
    });

    this.eventBroadcastService.onChangeStatut
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        // console.log('event change ');
        if (event === undefined) {
          return;
        }
        if (event === null) {
          return;
        }
        if (event.eventType !== undefined) {
          if (event.eventType === 'config') {
            this.config = event.eventData;
            this.initData();
          }
          if (event.eventType === 'identifier') {
            this.config.identifier = event.eventData;
            this.buildIdentifierBlock();
          }
          return;
        }

      });
  }
  initBooleans(): void {
    this.isFormLoaded = false;
    this.isAddressLoaded = false;
    this.isPhonesLoaded = false;
    this.isDataIn = false;
    this.isProfessionnal = false;
  }
  initData(): void {
    this.initSubscribes();
    this.initBooleans();
    switch (this.config.identifier) {
      case 'professional': {
        this.isProfessionnal = true;
        break;
      } // end case

      default: {
        this.isProfessionnal = false;
        break;
      } // end case
    }
    this.phonesList = [];
    this.emailsList = [];
    this.countryLoaded = undefined;
    this.langCur = this.translateService.currentLang;
    this.testAlone = this.config.testAlone;
    this.countryDefault = (this.config.countryDefault ? this.config.countryDefault
                            : this.authService.getUserLogged()
                            ? this.authService.getUserLogged().entitycountry
                            : undefined);
    this.address = this.config.address;
    this.telephone = this.config.telephone;
    this.contact = this.config.contact;
    this.corporate = this.config.corporate;
    this.email = this.config.email;
    this.isReadOnly = (this.config.isReadOnly ? this.config.isReadOnly : false);
    this.inputLatLng = (this.config.latLng ? this.config.latLng : false);

    this.countryKeyToLoad = this.countryDefault;

    if ((this.dataIn !== undefined) && (this.dataIn !== null)) {
      if (typeof this.dataIn === 'string') {
        this.dataCur = JSON.parse(this.dataIn);
      } else {
        this.dataCur = JSON.parse(JSON.stringify(this.dataIn));
      }
      /*
      if (this.dataIn.address !== undefined) {
        this.mapAddress = new Map<string, string>();
        const mapAdd = Object.entries(this.dataIn.address);
        for (const k in mapAdd) {
          if (mapAdd.hasOwnProperty(k)) {
            const value = mapAdd[k] as string[];
            this.mapAddress.set(value[0], value[1]);
          }
        }
      }*/
      // this.dataCur = JSON.parse(this.dataIn);
      // this.dataCur = this.dataIn;
      this.dataOrig = Object.assign('', this.dataCur);
      this.countryKeyToLoad = (this.dataCur.country !== undefined ? this.dataCur.country : this.countryDefault);
      this.isDataIn = true;
    } else {
      this.isAddressLoaded = true;
      this.isPhonesLoaded = true;
      this.isDataIn = false;
    }

    this.countryCur = this.sharingDataService.getCountry(this.countryKeyToLoad);
    this.isDomesticCountry = true;
    this.phoneFormat = JSON.stringify(this.countryCur.telephone);
    this.phonePrefix = this.countryCur.telephone.ITU_T_Code;

    const civilityItems = this.sharingDataService.getCivilityList();
    this.civilityList = [];

    for (const itemCur of civilityItems) {
      const labelCur = this.translateService.instant('CIVILITIES.' + itemCur);
      const opt = {
        item: itemCur,
        label: labelCur,
      };
      this.civilityList.push(opt);
    }
    const deptsItem = this.sharingDataService.getDeptsList();
    this.deptList = [];

    for (const item of deptsItem) {
      const labelCur = this.translateService.instant('ADDRESS_BLOCK.' + item);
      const opt = {
        value: item,
        label: labelCur,
      };
      this.deptList.push(opt);
    }
    this.deptList.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    const countriesListTmp = this.sharingDataService.getCountriesList();

    this.countriesCacheList = [];
    for (const countryTmp of countriesListTmp) {
      const labelLang = this.sharingDataService.getLabelCountry(this.langCur.toUpperCase(), countryTmp.commonName);
      this.countriesCacheList.push({
        isoAlpha2: countryTmp.isoAlpha2,
        commonName: labelLang,
      });
    }

    this.countriesCount = this.countriesCacheList.length;
    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.commonName > obj2.commonName ? 1 : -1;
    });

    this.countriesFilteredList = [...this.countriesCacheList];

    if (this.sharingDataService.getPhonesType() !== undefined) {
      this.phoneTypeList = this.sharingDataService.getPhonesType();
    }
    if (this.sharingDataService.getNotifApp() !== undefined) {
      this.appList = this.sharingDataService.getNotifApp();
    }

    this.selectAppsFg = new FormGroup({});
    this.identifierBlockFg = new FormGroup({});
    this.addressBlockFg = new FormGroup({});
    this.latLngBlockFg = new FormGroup({});
    this.contactBlockFg = new FormGroup({});
    this.corporateBlockFg = new FormGroup({});
    this.telephoneListFg = this.fb.array([this.buildItemTel('')]);
    this.emailsListFg = this.fb.array([this.buildItemEmail('')]);

    this.addressForm = new FormGroup({
      identifierBlockFg: this.identifierBlockFg,
      contactBlockFg: this.contactBlockFg,
      corporateBlockFg: this.corporateBlockFg,
      selectAppsFg: this.selectAppsFg,
      countrySelect: new FormControl('', [Validators.required]),
      filterCountry: new FormControl(''),
      addressBlockFg: this.addressBlockFg,
      latLngBlockFg: this.latLngBlockFg,
      telephoneListFg: this.telephoneListFg,
      emailsListFg: this.emailsListFg,
    });

    if (this.countryKeyToLoad) {
      /*this.addressForm.patchValue({
        filterCountry: '',
        countrySelect: this.countryKeyToLoad,
      });*/
      this.addressForm.controls.filterCountry.setValue('');
      this.addressForm.controls.countrySelect.setValue(this.countryKeyToLoad);
    }

    this.buildIdentifierBlock();
    this.buildContactsBlock();
    this.buildCorporateBlock();
    this.buildAddressBlock(this.countryKeyToLoad);
    if (this.telephone) {
      this.loadPhones();
    }
    if (this.email) {
      this.loadEmails();
    }
    this.isFormLoaded = true;
  }
  ngAfterContentInit(): void {
    if (this.config === undefined) {
      return;
    }
    if ((this.config.telephone) && ((this.phonesList === null) || (this.phonesList.length === 0))) {

      const phoneLengthMax = this.sharingDataService.getCountry(this.countryDefault).telephone.phoneLengthMax.split(';');

      const lengthCur = Number.parseInt(phoneLengthMax[0], 10);
      const lengthAdd = Number.parseInt(phoneLengthMax[1], 10);

      const phoneRow = {
        controlFmt: this.sharingDataService.getCountry(this.countryDefault).telephone.domesticFmt,
        displayFmt: this.sharingDataService.getCountry(this.countryDefault).telephone.domesticDisplay,
        phoneLengthMax: this.sharingDataService.getCountry(this.countryDefault).telephone.phoneLengthMax,
        phoneLength: (lengthCur + lengthAdd).toString(),
        phoneCountry: this.countryDefault,
        phonePrefix: '',
        phoneNumber: '',
        phoneType: '',
        dataType: 'N',
        inputError: false,
      };

      this.phonesList.push(phoneRow);
    }
    if ((this.config.email) && ((this.emailsList === null) || (this.emailsList.length === 0))) {
      const emailRow = {
        controlFmt: this.emailAttr.emailRegex,
        displayFmt: this.emailAttr.emailDisplay,
        emailAddress: '',
        emailType: '',
        dataType: 'AN',
      };
      this.emailsList.push(emailRow);
    }

  }
  closeModal(): void {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }
  // ================================ maps
  displayMap(): void {

    let positionCur ;
    let zoom = 1;
    let latLng: LatLng;
    if ((this.latLngBlockFg.controls.addressPosition !== undefined)
      && (this.latLngBlockFg.controls.addressPosition.value !== null)
      && (this.latLngBlockFg.controls.addressPosition.value !== '')) {
      zoom = 14;
      positionCur = this.latLngBlockFg.controls.addressPosition.value;
      const values = positionCur.split(',');
      latLng = {
          lat: Number.parseFloat(values[0]),
          lng: Number.parseFloat(values[1])
      };
    }
    this.googleMaps = {
      isModal: true,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'getLatLng',
      position: latLng,
      mapOptions:  {
        center: latLng,
        zoom : 1
    },
    markers:  [],
    };
    if (positionCur !== undefined) {
      this.googleMaps.markers.push({
        position: latLng,
        title: ''}
      );
    }
    this.openModalMap();
  }
  openModalMap(): void {

    const configModalMap = {
      class: 'modal-dialog-centered handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }
  checkGeoLocAddress(): void {

    let addressCur: string = null;
    for (const rowCur of this.rowArray)  {
      for (const colCur of rowCur.cols) {
        const value = this.addressBlockFg.controls[colCur.dataName].value;
        if ((value !== undefined) && (value !== null) && (value !== '')) {
          if (addressCur === null) {
            addressCur = value;
          } else {
            addressCur += ',' + value;
          }
        }
      }
    }
    addressCur += ',' + this.countryCur.commonName;
    // console.log(addressCur);
    this.searchLatLngMap(addressCur);
  }
  searchLatLngMap(addressArg: string): void {

    this.googleMaps = {
      isModal: true,
      addressInput: addressArg,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'searchLatLng',
      position: undefined,
      mapOptions:  { },
    markers:  [],
    };
    this.openModalMap();
  }
  displayLatLng(coord: LatLng): void {
    const wrongPosition = this.translateService.instant('anchoragePositionCoordError');
    const values: string[] = [];
    values[0] = coord.lat.toString();
    values[1] = coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((this.latLngBlockFg.controls !== undefined) &&
        (this.latLngBlockFg.controls.addressPosition !== undefined))  {
      if ((latLng !== undefined) && (latLng !== null)) {
          this.latLngBlockFg.controls.addressPosition.setValue(values[0] + ',' + values[1]);
          this.latLngBlockFg.controls.addressPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
          this.positionChange();
      } else {
        this.latLngBlockFg.controls.addressPositionCoord.setValue(wrongPosition);
      }
    }
  }
  getLatLng(values: string[]): number[] {
    const latLng: number[] = [];
    const lat = Number.parseFloat(values[0]);
    const lng = Number.parseFloat(values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this.coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude) as number;
    latLng[1] = this.coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude) as number;
    return latLng;
  }
  positionChange(): void {
    const addressPosition = this.latLngBlockFg.controls.addressPosition.value;
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'latLng',
      eventOrig : 'latLng',
      eventIdx: -1,
      eventCol: -1,
      eventValue: addressPosition,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }
  updateData(dataReturn: any): void {

    if (dataReturn !== '') {
      dataReturn.formAdressValid = (this.addressForm.status === 'VALID');
    }
    if (JSON.stringify(this.dataIn) !== JSON.stringify(dataReturn)) {
      this.dataOut.emit(dataReturn);
    }
  }
  updateErrorInput(event: any): void {

    if (event.inputError === undefined) {
      return;
    }
    switch (event.eventBloc) {
      case 'identifier' : {
        this.identifier.inputError = event.inputError;
        break;
      }
      case 'corporate': {

        break;
      }
      case 'contact': {

        break;
      }
      case 'address': {
        if (this.rowArray[event.eventIdx].cols[event.eventCol] !== undefined) {
          this.rowArray[event.eventIdx].cols[event.eventCol].inputError = event.inputError;
        }
        break;
      }
      case 'phoneRow': {
        if (this.phonesList[event.eventIdx] !== undefined) {
          this.phonesList[event.eventIdx].inputError = event.inputError;
        }
        break;
      }
      case 'emailRow': {
        if (this.emailsList[event.eventIdx] !== undefined) {
          this.emailsList[event.eventIdx].inputError = event.inputError;
        }
        break;
      }
      default: {
        break;
      }
    }

  }
  onSelectionCountryChangeMat($event: any): void {
    const valueArg = $event.value;

    this.changeCountry(valueArg);
  }

  changeCountry(valueArg: string): void {
    // console.log(event.value);

    this.countryKeyToLoad = valueArg;
    if (this.countryCur === undefined) {
      this.countryCur = this.sharingDataService.getCountry(this.countryKeyToLoad);
    } else if (this.countryCur.isoAlpha2 !== this.countryKeyToLoad) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      // const phonesElem = Object.keys(this.telephoneList[0].controls);
      let arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
      let nbRow = arrayGrp.length;
      for (let row = 0; row < nbRow; row++) {
        arrayGrp.removeAt(row);
      }
      arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
      nbRow = arrayGrp.length;
      for (let row = 0; row < nbRow; row++) {
        arrayGrp.removeAt(row);
      }
      this.dataCur = undefined;
      this.updateData('');
      this.phonesList = [];
      this.emailsList = [];
      this.countryCur = this.sharingDataService.getCountry(this.countryKeyToLoad);
      this.countryLoaded = undefined;
    }
    if (this.countryLoaded !== this.countryKeyToLoad) {
      this.buildAddressBlock(this.countryKeyToLoad);
      if (this.identifierInput) {
        this.buildIdentifierBlock();
      }
      if (this.telephone) {
        this.loadPhones();
      }
      if (this.email) {
        this.loadEmails();
      }
    }
    this.isFormLoaded = true;
  }
  onPhoneCountryChangeMat(event: any,  iRow: number): void {

    const countryCur = this.sharingDataService.getCountry(event.value) as CountryDef;
    this.phonesList[iRow].phoneCountry = countryCur.isoAlpha2;
    this.phonesList[iRow].phonePrefix = countryCur.telephone.ITU_T_Code;
    this.phonesList[iRow].phoneLengthMax = this.sharingDataService.getCountry(countryCur.isoAlpha2).telephone.phoneLengthMax;
    this.phonesList[iRow].phoneNumber = '';
    this.phonesList[iRow].inputError = false;
    const phoneLengthCur = this.sharingDataService.getCountry(this.countryDefault).telephone.phoneLengthMax.split(';');
    const lengthCur = Number.parseInt(phoneLengthCur[0], 10);
    const lengthAdd = Number.parseInt(phoneLengthCur[1], 10);
    this.phonesList[iRow].phoneLength = (lengthCur + lengthAdd).toString();
    if (event.value !== this.countryDefault) {
      this.phonesList[iRow].displayFmt = this.sharingDataService.getCountry(countryCur.isoAlpha2).telephone.E_164_Display;
      this.phonesList[iRow].controlFmt = this.sharingDataService.getCountry(countryCur.isoAlpha2).telephone.E_164_Fmt;
    } else {
      this.phonesList[iRow].displayFmt = this.sharingDataService.getCountry(countryCur.isoAlpha2).telephone.domesticDisplay;
      this.phonesList[iRow].controlFmt = this.sharingDataService.getCountry(countryCur.isoAlpha2).telephone.domesticFmt;
    }
    let updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'phoneRow',
      eventOrig : 'phoneCountry',
      eventIdx: iRow,
      eventCol: -1,
      eventValue: this.phonesList[iRow].phoneCountry,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
    updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'phoneRow',
      eventOrig : 'phonePrefix',
      eventIdx: iRow,
      eventCol: -1,
      eventValue: this.phonesList[iRow].phonePrefix,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }
  onSelectionStateChangeMat($event: any): void {
    // const valueArg = ($event.target as HTMLInputElement).value;
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'address',
      eventOrig : 'state',
      eventIdx: -1,
      eventCol: 0,
      eventValue: $event.value,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));

  }

  onSelectionEmailTypeChangeMat($event: any, iRow: number): void {

    // const valueArg = ($event.target as HTMLInputElement).value;
    this.emailsList[iRow].emailType = $event.value;
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'emailRow',
      eventOrig : 'emailType',
      eventIdx: iRow,
      eventCol: -1,
      eventValue: $event.value,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }

  onSelectionPhoneTypeChangeMat($event: any, iRow: number): void {
    // const valueArg = ($event.target as HTMLInputElement).value;
    this.phonesList[iRow].phoneType = $event.value;
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'phoneRow',
      eventOrig : 'phoneType',
      eventIdx: iRow,
      eventCol: -1,
      eventValue: $event.value,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }
  onKeyCountry($event: any): void {
    // const valueArg = ($event.target as HTMLInputElement).value;
    this.countriesFilteredList = this.searchCountry($event);
  }
  searchCountry(value: string): CountryLabel[] {
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  corporateChange(value: string): void {
    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    this.dataCur.corporateName = value;
    this.dataCur.cpltEventName = (this.config.cpltEventName ? this.config.cpltEventName : undefined);
    // this.dataIn = JSON.stringify(this.dataCur);
    this.updateData(this.dataCur);
  }
  contactChange(part: string, value: any): void {
    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    if (this.dataCur.identity === undefined) {
      this.dataCur.identity = {
        cntCivility: '',
        cntFirstname: '',
        cntLastname: '',
      };
    }
    this.dataCur.cpltEventName = (this.config.cpltEventName ? this.config.cpltEventName : undefined);
    switch (part) {
      case 'civility': {
        this.dataCur.identity.cntCivility = value.value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'firstname': {
        this.dataCur.identity.cntFirstname = value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'lastname': {
        this.dataCur.identity.cntLastname = value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'selectApps': {
        this.dataCur.selectApps = CommonMethods.arrayStringToString(value, ',');
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'selectDept': {
        this.dataCur.selectDept = CommonMethods.arrayStringToString(value, ',');
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'fonctionDept': {
        this.dataCur.fonctionDept = value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
    }
  }
  formatField(typeRaw: string, iRow: number, iCol: number, paramsCur: any, $event: Event): string {

    const valueArg = ($event.target as HTMLInputElement).value;

    let valueReturn: string;
    let blocField: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;
    let isDomesticCountry: boolean;
    let updateInput: {
      cpltEventName: string,
      eventBloc: string,
      eventOrig: string,
      eventIdx: number,
      eventCol?: number,
      eventValue: string,
      inputError: boolean,
    };
    const defaultFmtTel = '([0-9]{2})([0-9]{4})([0-9]{4})';
    const defaultEmailFmt = '(^[a-z]+[a-z0-9_\.]+)(@)([a-z]+)([\.][a-z]{2,5}$)';
    const defaultEmailDisplay = '$1$2$3$4';
    const defaultDisplayTel = '($1) $2 $3';
    const defaultLength = '10;4';
    let  inputErrorCur: boolean;

    // console.log(typeRaw + ' ' + iRow.toString() + ' ' + paramsCur + ' ' + valueCur);
    inputValue = valueArg;
    inputErrorCur = false;

    blocField = 'address';
    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      updateInput = {
        cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
        eventBloc: blocField,
        eventOrig : dataName,
        eventIdx: iRow,
        eventCol: iCol,
        eventValue: valueReturn,
        inputError: inputErrorCur,
       };
      this.updateErrorInput(updateInput);
      this.updateData(this.getDataOut(updateInput));
      return valueReturn;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    const isTelephone = (typeRaw === 'telephone');
    const isEmail = (typeRaw === 'email');

    let fieldLength = 0;
    if (isTelephone) {
      blocField = 'phoneRow';
      dataName = 'phoneNumber';
      isNumeric = true;
      isDomesticCountry = (paramsCur.phoneCountry === this.countryDefault ? true : false);
      let phoneArray: string[];
      if ( paramsCur.phoneLengthMax === undefined) {
        paramsCur.phoneLengthMax = defaultLength;
      }
      phoneArray = paramsCur.phoneLengthMax.split(';');
      fieldLength = Number(phoneArray[0]);
    }
    if (isEmail) {
      blocField = 'emailRow';
      dataName = 'emailAddress';
      fieldLength = paramsCur.emailLengthMax;
    }

    if (isNumeric) {
      inputValue = inputValue.replace(/\D/g, '');
    }
    if ((isTelephone) && (!isDomesticCountry)) {
      inputValue = inputValue.replace(/^(?!00[^0])0/, '');
    }

    let pattern: any;
    let fmtCur: string ;
    if (isEmail) {
      pattern = new RegExp(defaultEmailFmt, 'i');
      displayFmt = paramsCur.displayFmt;
    } else if (!isTelephone) {
      pattern = new RegExp(paramsCur.controlFmt, 'i');
    } else {
      if (paramsCur.controlFmt === undefined) {
        paramsCur.controlFmt = defaultFmtTel;
      }
      if (paramsCur.displayFmt === undefined) {
        paramsCur.displayFmt = defaultDisplayTel;
      }
      fmtCur = paramsCur.controlFmt;
      displayFmt = paramsCur.displayFmt;

      if (displayFmt === undefined ) {
        fmtCur = defaultFmtTel;
        displayFmt = defaultDisplayTel;
      }
      pattern = new RegExp(fmtCur, 'i');
    }
    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    if (!isEmail) {
      matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);
    } else {
      matchesGroup = inputValue.replace(pattern, defaultEmailDisplay);
    }

    if (testMatch === null) {

      inputErrorCur = true;
    } else {
      inputErrorCur = false;
    }
    valueReturn = matchesGroup;
    if (isTelephone) {
      this.phonesList[iRow].phoneNumber = valueReturn;
    } else if (isEmail) {
      this.emailsList[iRow].emailAddress = valueReturn;
    } else {
      if (typeRaw === 'identifier') {
        this.identifierBlockFg.controls[typeRaw].setValue(valueReturn);
        blocField = typeRaw;
      } else {
        this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      }
    }
    updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: blocField,
      eventOrig : dataName,
      eventIdx: iRow,
      eventCol: iCol,
      eventValue: valueReturn,
      inputError: inputErrorCur,
     };
    this.updateErrorInput(updateInput);
    this.updateData(this.getDataOut(updateInput));
    return valueReturn;
  }
  buildCorporateBlock(): void {
    let valueCur: string;
    const fieldName = 'corporateName';
    if (this.corporate) {
      if (this.dataCur !== undefined) {
        valueCur = (this.dataCur[fieldName] === undefined ? '' : this.dataCur[fieldName]);
      }
    } else {
      valueCur = '';
    }

    this.corporateBlockFg.addControl(fieldName, new FormControl({value: valueCur, disabled: this.isReadOnly}));
  }
  buildIdentifierBlock(): void {
    this.identifierInput = false;

    if (this.config.identifier === undefined) {
      return;
    }
    if (this.config.identifier === 'unknow') {
      this.identifierBlockFg.removeControl('identifier');
      return;
    }
    const identifierDefault = {
      dataType: 'AN',
      dataLengthMax: '25',
      controlFmt: '.*',
    };
    this.identifier = undefined;
    const elemsAddr = this.countryCur.address.elemAddr;
    for (const elemAddr of elemsAddr) {
      if ((elemAddr.dataInput === 'proID' || elemAddr.dataInput === 'vatID') && (this.config.identifier === 'professional')) {
        elemAddr.elemLabel = this.translateService.instant('ADDRESS_BLOCK.' + elemAddr.dataName);
        // elemAddr.elemLabel = this.translateService.instant(elemAddr.dataName);
        elemAddr.inputValue = '';
        this.identifierMandatory = (elemAddr.dataMandatory === 'N' ? false : true);
        this.identifier = elemAddr;
        break;
      }
      if ((elemAddr.dataInput === 'individualID')  && (this.config.identifier === 'individual')) {
        elemAddr.elemLabel = this.translateService.instant('ADDRESS_BLOCK.' + elemAddr.dataName);
        // elemAddr.elemLabel = this.translateService.instant(elemAddr.dataName);
        elemAddr.inputValue = '';
        this.identifierMandatory = (elemAddr.dataMandatory === 'N' ? false : true);
        this.identifier = elemAddr;
        break;
      }
    }
    if (this.identifier === undefined) {
      return;
    }
    /*
    if (this.identifier === undefined) {
      this.identifier = {
        elmtNumber: '0',
        lineNumber: '0',
        lineDataIndex: '0',
        dataLengthMax: identifierDefault.dataLengthMax,
        controlFmt:  identifierDefault.controlFmt,
        dataInput: this.config.identifier,
        dataName: this.config.identifier,
        elemCountry: this.config.countryDefault,
        dataType: 'AN',
        dataMandatory: 'N',
        inputValue: '',
        dataValue: '',
        elemLabel: this.translateService.instant(this.config.identifier),
      };
    }
    */
    this.identifierInput = true;
    const valueCur = (((this.dataCur === undefined) || (this.dataCur.identifier === undefined)) ? '' : this.dataCur.identifier);
    this.identifier.inputValue = valueCur;
    this.identifier.inputError = false;

    this.identifierBlockFg.addControl('identifier', new FormControl({value: valueCur, disabled: this.isReadOnly}));
  }
  buildContactsBlock(): void {

    const contactFields = ['cntCivility', 'cntFirstname', 'cntLastname'];
    let valueCur: string;
    for (const fieldName of contactFields) {
      if (this.contact) {
        if ((this.dataCur !== undefined) && (this.dataCur.identity !== undefined)) {
          valueCur = (this.dataCur.identity[fieldName] === undefined ? '' : this.dataCur.identity[fieldName]);
        }
      } else {
        valueCur = '';
      }

      this.contactBlockFg.addControl(fieldName, new FormControl({value: valueCur, disabled: this.isReadOnly}));
    }

    if (this.isProfessionnal) {
      const professionnalFields = ['selectDept', 'fonctionDept'];
      for (const fieldName of professionnalFields) {
        if (this.dataCur !== undefined ) {
          valueCur = (this.dataCur[fieldName] === undefined ? '' : this.dataCur[fieldName]);
        } else {
          valueCur = '';
        }
        this.contactBlockFg.addControl(fieldName, new FormControl({value: valueCur, disabled: this.isReadOnly}));
      }
      if ((this.dataCur !== undefined) && (this.dataCur.selectDept !== undefined)) {
        const selectDepts = this.dataCur.selectDept.split(',');
        this.contactBlockFg.controls.selectDept.setValue(selectDepts);
      }
    }
    this.selectAppsFg.addControl('selectApps', new FormControl({value: '', disabled: this.isReadOnly}));
    if ((this.dataCur !== undefined) && (this.dataCur.selectApps !== undefined)) {
      const selectApps = this.dataCur.selectApps.split(',');
      this.selectAppsFg.controls.selectApps.setValue(selectApps);
    }

  }
  // ------------------------------------------------------------------------------ address block
  buildAddressBlock(country: string): void {

    if (country === '') {
      return;
    }
    let noDataIn = false;
    let noAddressIn = false;

    if (!this.address)  {
      return;
    }
    if ((this.dataCur === undefined)) {
      noDataIn = true;
      noAddressIn = true;
    } else if ((this.dataCur.address === undefined)) {
      noAddressIn = true;
    }
    if ((this.dataIn !== undefined) && (this.dataIn !== null)) {
      this.isDataIn = true;
    }
    this.countryLoaded = this.countryKeyToLoad;

    let address = this.countryCur.address;
    if (this.config.localAddress && this.countryCur.localAddress) {
      address = this.countryCur.localAddress;
    }
    const elemsAddr = address.elemAddr;
    let rowsUpperCase: string | string[] = [];
    try {
      rowsUpperCase = address.uppercaseLastLines.split(';') as string[];
    } catch (err) {
    }
    if (elemsAddr === undefined) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      return;
    }
    // console.log(elemsAddr);
    let iRow = 0;
    const identityField = this.config.identifier;
    const fieldsId = this.config.fieldsId;
    let iRowCur = 0;
    this.rowArray = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;
    const labelOk = false;
    for (const elemAddr of elemsAddr) {
      iRowCur++;

      // JLG - 2022-09-17
      /*
      if (iRowCur < 3) { // recipient lines
        if (identityField === undefined) { // recipient lines
          continue;
        } else {
          if ((identityField === 'pro') && (!labelOk)) {
            elemAddr.dataName = 'denomination';
            labelOk = true;
          } else
          if ((identityField === 'perso') && (!labelOk)) {
            elemAddr.dataName = 'fullName';
            labelOk = true;
          } else if (!labelOk) {
            continue;
          }
        }
      }*/
      // console.log(elemAddr.dataName);
      if ((elemAddr.controlType === null) || (elemAddr.controlType === '')) {
        elemAddr.controlType = elemAddr.dataName;
      }
      iRow = Number(elemAddr.lineNumber);
      if (elemAddr.controlType === 'statesList') {
        this.buildStatesList(address.statesList);
        this.addressBlockFg.addControl('filterState', new FormControl());
      }
      if (elemAddr.dataInput === 'N') { continue; }

      if ((elemAddr.dataInput === 'proID') || elemAddr.dataInput === 'vatID' || (elemAddr.dataInput === 'individualID')) {
          continue;
      }
      if ((iRowBuild > 0) && (iRow !== iRowBuild)) {
        this.rowArray.push({
          row: Number(iRow).toString,
          lengthMax: iLengthMax,
          cols: colsArray,
        });
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      if (iRowBuild < 0) {
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      iLengthMax += Number(elemAddr.dataLengthMax);
      const labelElem = this.translateService.instant('ADDRESS_BLOCK.' + elemAddr.dataName);
      if (labelElem === elemAddr.dataName) {
        elemAddr.elemLabel = ''; // if label is ''
      } else {
        elemAddr.elemLabel = labelElem;
      }
      // elemAddr.elemLabel = this.translateService.instant(elemAddr.dataName);
      if (noDataIn || noAddressIn) {
        elemAddr.inputValue = '';
      } else {
        elemAddr.inputValue = (this.dataCur.address[elemAddr.dataName] === undefined ? '' : this.dataCur.address[elemAddr.dataName]);
      }
      elemAddr.elemUpperCase = false;
      if (rowsUpperCase.includes(elemAddr.lineNumber)) {
        elemAddr.elemUpperCase = true;
      }
      colsArray.push(elemAddr);
      this.addressBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: this.isReadOnly}));
      this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
      if (noDataIn) {
        this.dataCur = {};
      }
      if (noAddressIn) {
        this.dataCur.address = [];
      }
      if ((this.dataCur.address[elemAddr.dataName] === undefined)) {
        this.dataCur.address[elemAddr.dataName] = '';
      }
    } // elem address loop
    this.rowArray.push({
      row: Number(iRow).toString,
      lengthMax: iLengthMax,
      cols: colsArray,
    });
    if (this.inputLatLng)  {
      this.buildLatLngBlock();
    }
    this.isAddressLoaded = true;

  }
  buildLatLngBlock(): void {
    this.latLngBlockFg.addControl('addressPosition', new FormControl({value: '', disabled: this.isReadOnly}));
    this.latLngBlockFg.addControl('addressPositionCoord', new FormControl({value: '', disabled: this.isReadOnly}));
    if ((this.dataCur !== undefined )
      && (this.dataCur.addressPosition !== undefined )
      && (this.dataCur.addressPosition !== '' )) {
        this.latLngBlockFg.controls.addressPosition.patchValue(this.dataCur.addressPosition, { emitEvent: true, onlySelf: false });
        const latLng  = this.getLatLng(this.dataCur.addressPosition.split(','));
        this.latLngBlockFg.controls.addressPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.latLngBlockFg.controls.addressPosition.patchValue('', { emitEvent: true, onlySelf: false });
      this.latLngBlockFg.controls.addressPositionCoord.setValue('');
    }
  }
  getColRowAddr(cols: any[]): string {
    return 'col';
  }
  calWidthCol(bloc: string, cols: any[], dataLengthMaxArg: string, lengthMax: number): string {
    const dataLengthMax = Number(dataLengthMaxArg);
    let fieldSize = dataLengthMax * 11;
    if (fieldSize < 70 ) {
      fieldSize = fieldSize + (15 * 2);
    } else  {
      fieldSize = fieldSize + (50);
    }
    const blocWidth = window.innerWidth;
    // console.log(blocWidth);
    if (fieldSize > blocWidth) {
      fieldSize = blocWidth - 50;
    }
    return fieldSize.toString() + 'px';
  }
  calMarginLeft(iCol: number): string {
    let margin = '0px';
    const blocWidth = window.innerWidth;
    if (iCol > 0 && blocWidth > 1000) {
      margin = '5px';
    }

    return margin;
  }
  calMarginLeftField(): string {
    let margin = '0px';
    const blocWidth = window.innerWidth;
    if (blocWidth > 1000) {
      margin = '10px';
    }

    return margin;
  }
  calMarginTopField(): string {
    let margin = '0px';
    const blocWidth = window.innerWidth;
    if (blocWidth > 1000) {
      margin = '15px';
    }

    return margin;
  }
  calColNumber(colName: string, cols: any): string {

    let numberCol = 'col-sm-';
    const numberCur = cols.length ;
    const numberColCur = 12 / numberCur;
    numberCol += '' + numberColCur;
    console.log(colName + ' : ' + numberCol);
    return numberCol;
  }
  buildStatesList(stateList: any): void {
    this.statesCacheList = [];
    for (const stateTmp of stateList) {
      this.statesCacheList.push({
        stateName: stateTmp.stateName,
        stateAbb: stateTmp.stateAbb,
      });
    }

    this.statesCacheList.sort((obj1, obj2) => {
      return obj1.stateName > obj2.stateName ? 1 : -1;
    });

    this.statesFilteredList = [...this.statesCacheList];
  }
  setFilterState(selectInput: string): void {
    // console.log('selectInput : ' + selectInput);

    this.statesFilteredList = this.statesCacheList
      .filter(stateLoc => {
        const stateLoc1 = Object.values(stateLoc)[1];
        if (stateLoc1 !== undefined) {
          return stateLoc1.toLowerCase().includes(selectInput.toLowerCase());
        }
      });
  }
  // ------------------------------------------------------------------------------ load telephone
  loadPhones(): void {
    this.isDomesticCountry = (this.countryCur.isoAlpha2 === this.countryDefault);
    this.phoneFormat = JSON.stringify(this.countryCur.telephone);
    this.phonePrefix = this.countryCur.telephone.ITU_T_Code;
    if ((this.countryCur.telephone.phoneLengthMax === undefined) ||
        (this.countryCur.telephone.phoneLengthMax === '')) {
          this.countryCur.telephone.phoneLengthMax = '10;4';
    }
    const phoneLength = this.countryCur.telephone.phoneLengthMax.split(';');
    const lengthCur = Number.parseInt(phoneLength[0], 10);
    const lengthAdd = Number.parseInt(phoneLength[1], 10);

    this.phoneLengthMax = (lengthCur + lengthAdd).toString();

    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    if ((this.dataCur.phones === undefined) || (this.dataCur.phones === null)) {
      this.addPhone(0);
      this.dataCur.phones = this.phonesList;
      this.isPhonesLoaded = true;
      return;
    }
    if (this.dataCur.phones.length === 0) {
      this.addPhone(0);
      this.dataCur.phones = this.phonesList;
      this.isPhonesLoaded = true;
      return;
    }
    this.phonesList = this.dataCur.phones as PhoneInput[];
    const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
    arrayGrp.removeAt(0);
    for (const phoneTmp of this.phonesList) {
      // arrayGrp.push(this.buildItemTel(phoneTmp));
      const ctrlTmp = new FormGroup({
        phonePrefix: new FormControl({value: phoneTmp.phonePrefix, disabled: this.isReadOnly}),
        phoneNumber: new FormControl({value: phoneTmp.phoneNumber, disabled: this.isReadOnly}),
        phoneType: new FormControl({value: phoneTmp.phoneType, disabled: this.isReadOnly}),
      });
      // console.log(ctrlTmp);
      arrayGrp.push(ctrlTmp);
    }

    this.isPhonesLoaded = true;
    return;

  }
  addPhone(index: number): void {
    const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
    arrayGrp.push(this.buildItemTel(''));
    const phoneLengthMax = this.sharingDataService.getCountry(this.countryDefault).telephone.phoneLengthMax.split(';');

    const lengthCur = Number.parseInt(phoneLengthMax[0], 10);
    const lengthAdd = Number.parseInt(phoneLengthMax[1], 10);

    const phoneRow = {
      controlFmt: this.sharingDataService.getCountry(this.countryDefault).telephone.domesticFmt,
      displayFmt: this.sharingDataService.getCountry(this.countryDefault).telephone.domesticDisplay,
      phoneLengthMax: this.sharingDataService.getCountry(this.countryDefault).telephone.phoneLengthMax,
      phoneLength: (lengthCur + lengthAdd).toString(),
      phoneCountry: this.countryDefault,
      phonePrefix: '',
      phoneNumber: '',
      phoneType: '',
      dataType: 'N',
      inputError: false,
    };
    this.phonesList.push(phoneRow);
  }
  buildItemTel(val: any): FormGroup {
    if (val === '') {
      return new FormGroup ({
        phoneCountry: new FormControl(this.countryDefault),
        phonePrefix: new FormControl({value: '', disabled: this.isReadOnly}),
        phoneNumber: new FormControl({value: '', disabled: this.isReadOnly}),
        phoneType: new FormControl({value: '', disabled: this.isReadOnly}),
      });
    } else {
      if (val instanceof PhoneInput) {
        return new FormGroup ({
          phoneCountry: new FormControl({value: val.phoneCountry, disabled: this.isReadOnly}),
          phonePrefix: new FormControl({value: val.phonePrefix, disabled: this.isReadOnly}),
          phoneNumber: new FormControl({value: val.phoneNumber ?  val.phoneNumber : '', disabled: this.isReadOnly}),
          phoneType: new FormControl({value: val.phoneType, disabled: this.isReadOnly}),
        });
      } else  {
        return undefined;
      }
    }
  }
  removePhone(index: number): void {
    const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
    if (arrayGrp.length === 0) {
      return;
    }
    if (arrayGrp.length < 2) {
      return;
    }
    arrayGrp.removeAt(index);
    this.phonesList.splice(index, 1);
    if (arrayGrp.length === 0) {
      this.addPhone(-1);
    }
    this.dataCur.phones = this.phonesList;
  }
  // ------------------------------------------------------------------------------ load email
  loadEmails(): void {
    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    if ((this.dataCur.emails === undefined) || (this.dataCur.emails === null)) {
      this.addEmail(0);
      this.dataCur.emails = this.emailsList;
      this.isEmailsLoaded = true;
      return;
    }
    if (this.dataCur.emails.length === 0) {
      this.addEmail(0);
      this.dataCur.emails = this.emailsList;
      this.isEmailsLoaded = true;
      return;
    }
    this.emailsList = this.dataCur.emails as EmailInput[];
    const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
    arrayGrp.removeAt(0);
    for (const emailTmp of this.emailsList) {
      // arrayGrp.push(this.buildItemTel(phoneTmp));
      const ctrlTmp = new FormGroup({
        emailAddress: new FormControl({value: emailTmp.emailAddress, disabled: this.isReadOnly}),
        emailType: new FormControl({value: emailTmp.emailType, disabled: this.isReadOnly}),
      });
      // console.log(ctrlTmp);
      arrayGrp.push(ctrlTmp);
    }

    this.isEmailsLoaded = true;
    return;

  }
  addEmail(index: number): void {
    const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
    arrayGrp.push(this.buildItemEmail(''));
    const emailRow = {
      controlFmt: this.emailAttr.emailRegex,
      displayFmt: this.emailAttr.emailDisplay,
      emailAddress: '',
      emailType: '',
      dataType: 'AN',
      emailLengthMax: this.emailAttr.emailLengthMax,
    };
    this.emailsList.push(emailRow);
  }
  buildItemEmail(val: any): FormGroup {
    if (val === '') {
      return new FormGroup({
        emailAddress: new FormControl({value: '', disabled: this.isReadOnly}),
        emailType: new FormControl({value: '', disabled: this.isReadOnly}),
      });
    } else {
      if (val instanceof EmailInput) {
        return new FormGroup({
          emailAddress: new FormControl({value: val.emailAddress, disabled: this.isReadOnly}),
          emailType: new FormControl({value: val.emailType, disabled: this.isReadOnly}),
        });
      } else {
        return undefined;
      }
    }
  }
  removeEmail(index: number): void {
    const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
    if (arrayGrp.length === 0) {
      return;
    }
    if (arrayGrp.length < 2) {
      return;
    }
    arrayGrp.removeAt(index);
    this.emailsList.splice(index, 1);
    if (arrayGrp.length === 0) {
      this.addEmail(-1);
    }
    this.dataCur.emails = this.emailsList;
  }
  getDataOut(event: {
              cpltEventName: string,
              eventBloc: string,
              eventOrig: string,
              eventIdx: number,
              eventValue: string
            }): any {
    if (this.dataCur === undefined)  {
      this.dataCur = {};
    }

    this.dataCur.country = this.countryCur.isoAlpha2;
    this.dataCur.countryInput = this.countryDefault;
    if (event.cpltEventName !== undefined) {
      this.dataCur.cpltEventName = event.cpltEventName;
    }

    const phonePrefix = 'phonePrefix';
    //    const phonesList = [];
    if (event.eventBloc === 'appsSelected') {
      this.dataCur.appsSelected = event.eventValue;
    }
    if (event.eventBloc === 'address') {
      /* if (this.mapAddress === undefined) {
        this.mapAddress = new Map();
      }*/
      if (this.dataCur.address === undefined) {
        this.dataCur.address = {};
      }
      this.dataCur.address[event.eventOrig] = event.eventValue;
      /*
      this.mapAddress.set(event.eventOrig, event.eventValue);
      const result = Object.fromEntries(this.mapAddress);
      // console.log(result);
      // const result = JSON.stringify(this.mapAddress);
      this.dataCur.address = JSON.parse(JSON.stringify(result));
      */
    }
    if (event.eventBloc === 'phoneRow') {
    // getting phoneType Value
      if (this.dataCur.phones === undefined) {
        this.dataCur.phones = {};
      }
      /*this.dataCur.phones[event.eventIdx][event.eventOrig] = event.eventValue;
      this.dataCur.phones[event.eventIdx][phonePrefix] = this.phonePrefix;*/
      let phone: any;

      try {
        phone = this.dataCur.phones[event.eventIdx];
      } catch (err) {
      }
      if (phone === undefined) {
        phone = {
          phonePrefix: this.phonePrefix,
          phoneNumber:  (event.eventOrig  === 'phoneNumber' ?  event.eventValue : undefined),
          phoneType: (event.eventOrig  === 'phoneType' ?  event.eventValue : undefined),
        };
      } else {
        if (event.eventOrig  === 'phoneNumber') {
          phone.phoneNumber = event.eventValue;
        }
        if (event.eventOrig  === 'phoneType') {
          phone.phoneType = event.eventValue;
        }
      }
      this.dataCur.phones[event.eventIdx] = phone;

    } // end telephone test
    if (event.eventBloc === 'emailRow') {
      if (this.dataCur.emails === undefined) {
        this.dataCur.emails = {};
      }
      let email: any;

      try {
        email = this.dataCur.emails[event.eventIdx];
      } catch (err) {
      }
      if (email === undefined) {
        email = {
          emailAddress:  (event.eventOrig  === 'emailAddress' ?  event.eventValue : undefined),
          emailType: (event.eventOrig  === 'emailType' ?  event.eventValue : undefined),
        };
      } else {
        if (event.eventOrig  === 'emailAddress') {
          email.emailAddress = event.eventValue;
        }
        if (event.eventOrig  === 'emailType') {
          email.emailType = event.eventValue;
        }
      }
      this.dataCur.emails[event.eventIdx] = email;
    } // end email test
    if (event.eventBloc === 'identifier') {
      this.dataCur.identifier =  event.eventValue;
    }
    if (event.eventBloc === 'latLng') {
      this.dataCur.addressPosition =  event.eventValue;
    }
    // this.dataIn = JSON.stringify(this.dataCur);
    // this.dataIn = this.dataCur;
    return this.dataCur;
  }
}

