<mat-card style="padding: 0px;width: 100%;" >
	<mat-card-content class="webcam-wrapper">
		<!--<div class="webcam-wrapper" *ngIf="!error">-->
			<div class="flex-row row  stdTheme"  style="width: 100%;margin-left: 0px; margin-right: 0px">
				<div class="col-12">
					<!--
					<video [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" [width]="WIDTH" [height]="HEIGHT"	autoplay></video>
					<canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="WIDTH" [height]="HEIGHT"></canvas>
					-->
					<video #video [width]="getVideoWidth()" [height]="getVideoHeight()" [class.show]="!isCaptured" [class.hide]="isCaptured" autoplay muted playsinline (resize)="videoResize()"></video>
					<!--<div class="camera-switch" *ngIf="allowCameraSwitch && availableVideoInputs.length > 1 && videoInitialized" (click)="rotateVideoInput(true)"></div>-->
					<canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="width" [height]="height"></canvas>
				</div>
			</div>
		<!--</div>-->
	</mat-card-content>
	<mat-card-footer class="snap-container">
		<!--<div class="snap-container">-->
			<div class="flex-row row  stdTheme"  style="width: 100%;margin-left: 0px; margin-right: 0px">
				<div class="col-10">
					<button class="btn btn-primary" *ngIf="!isCaptured" (click)="capture()">Snap Photo</button>
					<button class="btn btn-danger" *ngIf="isCaptured" (click)="resetCurrent()">Take Another</button>
				</div>
				<div class="col-2">
					<button class="btn btn-primary"(click)="rotateVideoInput(true);" [disabled]="!multipleWebcamsAvailable">Change Webcam</button>
				</div>
			</div>
			<!--
			<div *ngIf="error" class="text-danger mt-4">
				{{ error }}. Please click refresh on stackblitz
				<div class="mt-5">
					<img src="https://trungk18.com/7e0976255739e721501803ee074f1f94/camera.gif" />
				</div>

			</div>
-->
			<div class="flex-row row  stdTheme"  style="width: 100%;margin-left: 0px; margin-right: 0px">
				<div class="col-12">
					<ul class="list-unstyled">
						<li *ngFor="let src of captures; let idx = index" (click)="setPhoto(idx)">
							<img [src]="src" height="50" />
						</li>
					</ul>
				</div>
			</div>
		<!--</div>-->
	</mat-card-footer>
</mat-card>