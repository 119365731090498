import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray} from '@angular/forms';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/services/appointment.service';
import { BoatComponentsDesc, JobsEquipments, WksEquipmentModel } from 'src/app/models';
import { BrandWksModel, TypesLabel } from 'src/app/models/works/wks-common.model';
import { ModalCommonComponent } from '../../../../components/common/modal-common/modal-common.component';

import { AuthService } from 'src/app/services/auth.service';
import { SharingDataService } from 'src/app/services/sharing-data.service';
import { WorksService } from 'src/app/services/works.service';

@Component({
  selector: 'app-wks-appointment-equip-job',
  templateUrl: './wks-appointment-equip-job.component.html',
  styleUrls: ['./wks-appointment-equip-job.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WksAppointmentEquipJobComponent implements OnInit {

  @Input() mainEquipment: WksEquipmentModel;
  @Input() subEquipmentMotors: WksEquipmentModel[];
  @Output() dataOutJobEquip = new EventEmitter<any>();

  private readonly onDestroy = new Subject<void>();

  entityCur: string;
  jobsForm: FormGroup;
  rowJobFg: FormGroup;
  jobsListArray: FormArray;

  jobsList: JobsEquipments[];

  listEquipType: BoatComponentsDesc[];
  subEquipmentsCur: WksEquipmentModel[][];
  OKToDisplay: boolean;
  OkDisplaySubEquipemntsList: boolean[];
  boatComponentsDesc: BoatComponentsDesc[];

  constructor(private fb: FormBuilder,
              private sharingDataService: SharingDataService,
              private translateService: TranslateService,
              private worksService: WorksService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.initData();
  }
  initData(): void {
    this.initAllBooleans();
    this.initVariables();
    this.initGlobalsParameters();
    this.buildForm();
    if (this.jobsList.length === 0) {
      this.addEmptyJobEquip(0);
    }
    this.subscribeAll();
    this.OKToDisplay = true;
  }
  initAllBooleans(): void {
    this.OKToDisplay = false;

  }
  initGlobalsParameters(): void {

    this.entityCur = this.authService.getUserLogged().entity;

    const listItem = this.sharingDataService.getBoatComponentsDesc();
    this.listEquipType = [];
    for (const item of listItem) {
      /*
      if (item.name.includes('engine')) {
        continue;
      }*/
      item.localLabel = this.translateService.instant('APPOINTMENT_DETAIL.JOB.COMPONENTS_LABEL.' + item.name);
      this.listEquipType.push(item);
    }
    this.listEquipType.sort((obj1, obj2) => {
      return obj1.localLabel > obj2.localLabel ? 1 : -1;
    });

  }
  subscribeAll(): void {
/*
    this.jobsListArray.valueChanges.subscribe(
      result => {
        let event = {};

        switch (this.jobsListArray.status) {
          case 'VALID':
            // WorkInputEvent
            // this.credentialsOK = true;
            event = {
              inputOK: true,
              jobsEquip: this.jobsList
            };
            this.dataOutJobEquip.emit(event);
            break;
          case 'INVALID':
            event = {
              inputOK: false,
              jobsEquip: this.jobsList
            };
            this.dataOutJobEquip.emit(event);
            break;
        }
      }
    );
    */
  }
  initVariables(): void {
    this.jobsList = [];
    this.subEquipmentsCur = [];
    this.OkDisplaySubEquipemntsList = [];
    this.boatComponentsDesc = [];
  }
  buildForm(): void {
    this.jobsListArray = this.fb.array([this.buildItemJob(undefined)]);
    this.jobsForm = new FormGroup({
      jobsListArray: this.jobsListArray,
    });
    this.jobsForm.setErrors({incorrect: true});
  }
  buildItemJob(val: JobsEquipments): FormGroup {

    if (val === undefined) {
      const fgCur = new FormGroup ({
        equipType: this.fb.control(''),
        equipName: this.fb.control(''),
        equipBrand: this.fb.control(''),
        equipModel: this.fb.control(''),
        requestedService: this.fb.control(''),
      });
      fgCur.setErrors({incorrect: true});

      return fgCur;
    } else {
        return new FormGroup ({
          equipType:  new FormControl({value: val.equipType}),
          equipName: new FormControl({value: val.equipName}),
          equipBrand: new FormControl({value: val.equipBrand ? val.equipBrand : ''}),
          equipModel: new FormControl({value: val.equipModel ? val.equipModel : ''}),
          requestedService: new FormControl({value: val.requestedService}),
        });
    }
  }
  initEmptytData(idxItem: number): void {
    const arrayGrp = this.jobsForm.get('jobsListArray') as FormArray;
    const fgCur = arrayGrp.at(idxItem) as FormGroup;
    fgCur.controls.equipName.setValue('');
    fgCur.controls.equipBrand.setValue('');
    fgCur.controls.equipModel.setValue('');
    fgCur.controls.requestedService.setValue('');
  }
  addEmptyJobEquip(idxItem: number): void {

    const arrayGrp = this.jobsForm.get('jobsListArray') as FormArray;
    if (idxItem === 0 && arrayGrp.length > 0) {
      arrayGrp.removeAt(0);
    }
    // this.jobsListArray.setErrors({'incorrect': true});
    // this.jobsForm.setErrors({'incorrect': true});
    arrayGrp.insert(idxItem, this.buildItemJob(undefined));
    const jobEquip: JobsEquipments = {
      equipId: undefined,
      equipType: undefined,
      equipName: undefined,
      requestedService: undefined
      };
    // this.jobsList.push(jobEquip);
    this.jobsList.splice(idxItem, 0, jobEquip);
    this.boatComponentsDesc.splice(idxItem, 0, undefined);

  }

  removeJob(idxItem: number): void {
    const arrayGrp = this.jobsForm.get('jobsListArray') as FormArray;
    if (arrayGrp.length === 0) {
      return;
    }

    arrayGrp.removeAt(idxItem);
    this.jobsList.splice(idxItem, 1);
    this.boatComponentsDesc.splice(idxItem, 1);

    if (arrayGrp.length === 0) {
      this.addEmptyJobEquip(0);
    }

  }
  onChange(field: string, idx: number, value: any): void {
    switch (field) {
      case 'equipId': {
        this.jobsList[idx].equipId = value;
        this.ctrlForm();
        break;
      }
      case 'equipType': {
        const descComp = value as BoatComponentsDesc;
        this.jobsList[idx].equipType = descComp.name;
        this.boatComponentsDesc[idx] = value;
        this.loadSubEquipements(idx);
        this.ctrlForm();
        break;
      }
      case 'equipName': {
        this.jobsList[idx].equipName = value;
        const itemNew: WksEquipmentModel = {
          stdEntity: this.entityCur,
          subEquipmentNew: true,
          equipPartOf: this.mainEquipment.id,
          ownerId: this.mainEquipment.ownerId,
          equipType: this.jobsList[idx].equipType,
          equipName: this.jobsList[idx].equipName,
          equipDesign: this.jobsList[idx].equipName,
          equipBrand: this.jobsList[idx].equipBrand,
          equipModel: this.jobsList[idx].equipModel,
        };

        this.jobsList[idx].subEquipment = itemNew;
        this.ctrlForm();
        break;
      }
      case 'subEquip': {
        if (value.subEquipmentNew) { // for creating new equipment
          this.OkDisplaySubEquipemntsList[idx] = false;
          this.initEmptytData(idx);
          this.ctrlForm();
          break;
        }
        this.jobsList[idx].equipName = value.equipName;
        value.subEquipmentNew = false;
        this.jobsList[idx].subEquipment = value;
        this.ctrlForm();
        break;
      }
      case 'requestedService': {
        this.jobsList[idx].requestedService = value;
        this.ctrlForm();
        break;
      }
    }
  }
  ctrlForm(): void {
    let event = {};
    switch (this.jobsListArray.status) {
      case 'VALID':
        // WorkInputEvent
        // this.credentialsOK = true;
        event = {
          inputOK: true,
          jobsEquip: this.jobsList
        };
        this.dataOutJobEquip.emit(event);
        break;
      case 'INVALID':
        event = {
          inputOK: false,
          jobsEquip: this.jobsList
        };
        this.dataOutJobEquip.emit(event);
        break;
    }
  }

  loadSubEquipements(idxItem: number): void {
    // 	liste équipements du type pour le client (equipmentListOwnerType)
    this.subEquipmentsCur[idxItem] = [];
    this.OkDisplaySubEquipemntsList[idxItem] = false;
    if (this.jobsList[idxItem].equipType === 'outboard engine' || this.jobsList[idxItem].equipType === 'inboard engine') {
      this.subEquipmentsCur[idxItem] =   this.subEquipmentMotors;
      for (const subEquip of this.subEquipmentsCur[idxItem])  {
            subEquip.subEquipmentNew = false;
      }
      this.subEquipmentsCur[idxItem].sort((obj1, obj2) => {
        return obj1.equipName > obj2.equipName ? 1 : -1;
      });
      this.OkDisplaySubEquipemntsList[idxItem] = true;
      return;
    }
    this.getWksEquipmentByPartOfandEquipType(this.mainEquipment.id, this.jobsList[idxItem].equipType)
    .then ((response: any) => {
      // si équipements existants
      this.subEquipmentsCur[idxItem] =   response  as WksEquipmentModel[];
      for (const subEquip of this.subEquipmentsCur[idxItem])  {
            subEquip.subEquipmentNew = false;
      }
      this.subEquipmentsCur[idxItem].sort((obj1, obj2) => {
        return obj1.equipName > obj2.equipName ? 1 : -1;
      });
      this.addNewEquipmentOption(idxItem);
      this.OkDisplaySubEquipemntsList[idxItem] = true;
    })
    .catch ((error: number) => {

      if (this.boatComponentsDesc[idxItem].equipName !== null && this.boatComponentsDesc[idxItem].equipName !== '') {
        const arrayGrp = this.jobsForm.get('jobsListArray') as FormArray;
        const fgCur = arrayGrp.at(idxItem) as FormGroup;
        const equipName = this.translateService.instant('WORK_EQUIPMENT.' + this.boatComponentsDesc[idxItem].equipName);
        fgCur.controls.equipName.setValue(equipName);
        this.onChange('equipName', idxItem, equipName);
      } else {
        const arrayGrp = this.jobsForm.get('jobsListArray') as FormArray;
        const fgCur = arrayGrp.at(idxItem) as FormGroup;
        fgCur.controls.equipName.setValue('');
        this.onChange('equipName', idxItem, '');
      }
      return;
    });
  }
  getWksEquipmentByPartOfandEquipType(equipPartOf: string, equipType: string ): any {

    return new Promise((resolve, reject) => {
      this.worksService.getWksEquipmentListBoat(this.entityCur, equipPartOf, equipType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body as WksEquipmentModel[]);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  addNewEquipmentOption(idxItem: number): void {

    const itemNew: WksEquipmentModel = {
      stdEntity: this.entityCur,
      subEquipmentNew: true,
      equipPartOf: this.mainEquipment.id,
      ownerId: undefined,
      equipType: undefined,
      equipName: this.translateService.instant('APPOINTMENT_DETAIL.JOB.EQUIPMENT.NEW_EQUIPMENT'),
      equipDesign: this.translateService.instant('APPOINTMENT_DETAIL.JOB.EQUIPMENT.NEW_EQUIPMENT')
    };
    this.subEquipmentsCur[idxItem].push(itemNew);
  }
}
