<div class="container-fluid" style="width: 100%;">
    <div class="flex-row row  stdTheme" style="width: 100%;">    
        <h1> ngx-scanner-qrcode </h1>
    </div>
    <!-- devices -->
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
        <select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-md">
          <option [value]="null" selected>Select device</option>
          <option *ngFor="let c of action.devices.value; let i = index" [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
        </select>
      </div>
  
      <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
        <select #select2 (change)="action.decode = select2.value" class="form-select form-select-md">
          <option value="utf-8" [selected]="action.decode == 'utf-8'">UTF-8</option>
          <option value="iso-8859-15" [selected]="action.decode == 'iso-8859-15'">ISO-8859-15</option>
          <option value="windows-1252" [selected]="action.decode == 'windows-1252'">Windows-1252</option>
          <option value="macintosh" [selected]="action.decode == 'macintosh'">Macintosh</option>
        </select>
      </div>

    </div>
    <div class="row">
      <div class="col-xs-12 col-md-12 col-lg-4 col-xl-3 my-1">
        <div *ngFor= "let qrCodeItem of qrCodeReaded; let rowJob=index; let isLast=last" >
          <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px; height: 20px;">
            <span>{{qrCodeItem}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- ngx-scanner-qrcode -->
    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
  
    <!-- data  -->
    <!-- <p class="data">{{ (action.data | async | json) }}</p> -->
  
    <!-- loading -->
    <p *ngIf="action.isLoading">⌛ Loading...</p>
  
    <!-- start/stop -->
    <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
      <img [src]="action.isStart ? 'https://id1945.github.io/images/svg/camera-off.svg' : 'https://id1945.github.io/images/svg/camera-on.svg'" width="30px"/>
    </button>
  
    <!-- play/pause -->
    <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="handle(action, action.isPause ? 'play' : 'pause')">
      <img [src]="action.isPause ? 'https://id1945.github.io/images/svg/play.svg': 'https://id1945.github.io/images/svg/pause.svg'" width="30px"/>
    </button>
  
    <!-- isTorch -->
    <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="action.isTorch = !action.isTorch; handle(action, 'torcher')">
      <img [src]="action.isTorch ? 'https://id1945.github.io/images/svg/flash-off.svg' : 'https://id1945.github.io/images/svg/flash-on.svg'" width="30px"/>
    </button>
  
    <!-- download -->
    <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="handle(action, 'download')">
      <img src="https://id1945.github.io/images/svg/capture.svg" width="30px"/>
    </button>
    <br>
  
    <!-- for select files -->
    <input #file type="file" (change)="onSelects(file.files)" [multiple]="'multiple'" [accept]="'.jpg, .png, .gif, .jpeg'" class="btn btn-success my-2"/>
    <br>
    <input #file2 type="file" (change)="onSelects2(file2.files)" [multiple]="'multiple'" [accept]="'.jpg, .png, .gif, .jpeg'" class="btn btn-success my-2"/>
    <br>
  
    <button (click)="onGetConstraints()" class="btn btn-success">Get</button>
    <button (click)="applyConstraints()" class="btn btn-success">App</button>
  
    <div class="d-flex justify-content-center my-2">
      <div class="col-xs-12 col-md-3 m-1">
        <select #select3 (change)="percentage = +select3.value" class="form-select form-select-md">
          <option *ngFor="let item of [10,20,30,40,50,60,70,80,90,100]" [value]="item" [selected]="item == percentage">Scale {{item}}%</option>
        </select>
      </div>
      <div class="col-xs-12 col-md-3 m-1">
        <select #select4 (change)="quality = +select4.value" class="form-select form-select-md">
          <option *ngFor="let item of [10,20,30,40,50,60,70,80,90,100]" [value]="item" [selected]="item == quality">Quality {{item}}%</option>
        </select>
      </div>
    </div>
  
    <div *ngFor="let item of qrCodeResult">
      <ngx-scanner-qrcode #actionFile="scanner" [src]="item.url" [config]="config" (event)="onEvent($event)"></ngx-scanner-qrcode>
      <!-- <p class="data">{{ actionFile.data | async }}</p> -->
    </div>
  
    <div *ngFor="let item of qrCodeResult2">
      <img [src]="item.url | safeHtml: 'url'" alt="" style="max-width: 100%">
      <!-- <p class="data">{{ item | json }}</p> -->
    </div>
  
    <ngx-scanner-qrcode
      #actionFile="scanner"
      (event)="onEvent($event)"
      [src]="'https://raw.githubusercontent.com/id1945/ngx-scanner-qrcode/master/supported-barcode-types.png'"
      [config]="config"> 
    </ngx-scanner-qrcode>
    <!-- <p class="data">{{ (actionFile.data | async | json) }}</p> -->
  
    <!-- license -->
    <p class="center"><a href="https://github.com/id1945/ngx-scanner-face/blob/master/LICENSE">LGPL-2.1+ License</a></p>
  
    <!-- copyright -->
    <p class="center">Author: DaiDH</p>
    <p class="center">Copyright (C) 1991, 1999 Free Software Foundation, Inc.</p>
  </div>
