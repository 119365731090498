import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, ViewEncapsulation, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil, throwIfEmpty } from 'rxjs/operators';
import { WorksService } from '../../../../services/works.service';
import { SharingDataService } from '../../../../services/sharing-data.service';
import { ModalCommonComponent } from '../../../../components/common/modal-common/modal-common.component';
import { WksBusinessModel } from 'src/app/models/works/wks-business.model';

import { AuthService } from '../../../../services/auth.service';
import { TypesLabel } from 'src/app/models/works/wks-common.model';
import { LabelValue, ParentParam, WksEquipmentModel, WksWorksInterventionModel, WksWorksModel,
          WksWorksTracking, WorkEquipmentRef, WorkEquipments, WorkStatutModel, RegularServiceItem,
          JobsAsked} from 'src/app/models';
import { unchangedTextChangeRange } from 'typescript';

@Component({
  selector: 'app-wks-appointment-detail',
  templateUrl: './wks-appointment-detail.component.html',
  styleUrls: ['./wks-appointment-detail.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WksAppointmentDetailComponent implements OnChanges {
  @Input() displayApptmntDetail: number;
  @Output()
  returnData = new EventEmitter<string>();

  private readonly onDestroy = new Subject<void>();
  okToDisplay: boolean;
  isWaiting: boolean;
  customerName: string;
  configModal = {
    class: 'modal-sm modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    size: 'sm'
  };
  owner: WksBusinessModel;
  addressCur: any;
  configAddress: any;
  isCompany: boolean;
  isPerson: boolean;
  isAdministration: boolean;
  isProfessionnal: boolean;
  // WORK
  wksWorks: WksWorksModel;
  workStatut: WorkStatutModel;
  wksWorkIntervention: WksWorksInterventionModel;
  wksWorksTracking: WksWorksTracking[];
  // EQUIPMENT
  addFirstEquipment: boolean;
  parentParam: ParentParam;

  equipmentChoiceDisplay: boolean;
  isEquipmentCompleted: boolean;
  workEquipments: WorkEquipments;
  workEquipmentId: WorkEquipmentRef;

  subEquipmentsCur: WksEquipmentModel[];

  mainEquipmentCur: WksEquipmentModel;
  subEquipmentsLoading: boolean;
  subEquipmentsLoaded: boolean;
  subEquipmentIsLoaded: boolean;
  subEquipmentNew: boolean;
  mainEquipmentsLoading: boolean;
  mainEquipmentsLoaded: boolean;
  mainEquipmentIsLoaded: boolean;
  isMainWorkEquip: boolean;
  workEquipmentsLoading: boolean;
  workEquipmentsLoaded: boolean;


  equipTypeList: TypesLabel[];

  attachedEquipement: boolean;

  mainEquipmentDisplay: boolean;
  subEquipmentDisplay: boolean;
  boatsList: WksEquipmentModel[];

  mainEquipmentNew: boolean;
  isBoatEquip: boolean;
  loadBoats: boolean;
  changeBoat: boolean;
  choiceBoatDisplay: boolean;
  mainItemType: string;
  subItemType: string;

  numberSubEquipements: number;
  numberSubEquipementsDisplay: boolean;
  numberMotors: number;

  entityCur: string;
  // SERVICE
  isServiceCompleted: boolean;
  serviceForm: FormGroup;
  equipmentChoiceForm: FormGroup;
  servicesList: LabelValue[];
  askedService: {
    label: string,
    type: string,
    hours: number
  };
  displayCountHours: boolean;

  hours: {
    min: number;
    max: number;
    step: number;
  };
  regularServices: RegularServiceItem[];
  isRegularService: boolean;
  submitDone: boolean;
  workIsSave: boolean;
  selectedIndexStepper = 0;
  jobsAsked: JobsAsked;

  constructor(private worksService: WorksService,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private authService: AuthService,
              private sharingDataService: SharingDataService,
              private dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'displayApptmntDetail': {
            // tslint:disable-next-line:no-string-literal
            this.displayApptmntDetail = changes['displayApptmntDetail'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.initBooleans();
    this.readCustomer();
    this.initGlobalsParameters();
    this.buildEquipmentChoiceBlock();
    this.buildServiceBlock();
  }
  initBooleans(): void {
    this.okToDisplay = false;
    this.isWaiting = true;
    this.submitDone = false;
    this.workIsSave = false;
    this.equipmentChoiceDisplay = false;

    this.attachedEquipement = false;
    // work equipment
    this.workEquipmentsLoading = false;
    this.workEquipmentsLoaded = false;
    // main equipemnt
    this.addFirstEquipment = false;
    this.mainEquipmentsLoaded = false;
    this.mainEquipmentsLoading = false;
    this.mainEquipmentDisplay = false;
    this.mainEquipmentNew = false;
    this.isMainWorkEquip = false;
    // sub equipment
    this.subEquipmentsLoading = false;
    this.subEquipmentsLoaded = false;
    this.subEquipmentDisplay = false;
    this.subEquipmentNew = false;
    this.numberSubEquipementsDisplay = false;
    // boat : main equipement for sub equipement
    this.changeBoat = false;
    this.choiceBoatDisplay = false;
    this.loadBoats = false;
    this.isEquipmentCompleted = false;
    // service
    this.isServiceCompleted = false;

    this.isRegularService = true;
    this.displayCountHours = true;
  }
  initGlobalsParameters(): void {

    this.entityCur = this.authService.getUserLogged().entity;
    const equipmentsAccepted = this.sharingDataService.getWksEntityParams().equipments;
    const listItem = this.sharingDataService.getComponentsList();
    this.regularServices = this.sharingDataService.getRegularServices();

    this.equipTypeList = [];
    for (const item of listItem) {
      for (const equipItem of equipmentsAccepted) {
        if (equipItem.type === item) {
          const itemCur: TypesLabel = {
            labelType: this.translate.instant('WORK_EQUIPMENT.' + item),
            equipType: item,
            equipAttachedBoat: true,
          };
          this.equipTypeList.push(itemCur);
        }
      }
    }
    const listEquip = this.sharingDataService.getEquipmentsList();
    for (const item of listEquip) {
      if (item.equipType === 'engine') {
        continue;
      }
      for (const equipItem of equipmentsAccepted) {
        if (equipItem.type === item.equipType) {
          const itemCur: TypesLabel = {
            labelType: this.translate.instant('WORK_EQUIPMENT.' + item.equipName),
            equipType: item.equipType,
            equipAttachedBoat: false
          };
          this.equipTypeList.push(itemCur);
        }
      }
    }
    this.equipTypeList.sort((obj1: TypesLabel, obj2: TypesLabel) => {
      return obj1.labelType > obj2.labelType ? 1 : -1;
    });
    this.hours = {
      min: 0,
      max: 0,
      step: 0
    };
  }
  buildListServices(equipType: string): void {

    this.servicesList = [];
    for (const equipCur of this.sharingDataService.getWksEntityParams().equipments) {
      if (equipCur.type === equipType)  {
        for (const serviceCur of equipCur.services ) {
          const prestationCur: LabelValue = {
            label : this.translate.instant('EQUIPMENT.' + serviceCur),
            value: serviceCur,
            values: [],
          };
          this.servicesList.push(prestationCur);
        }
        break;
      }
    }
  }

  submitAppointment(): void {
    const wksWorkCard = this.fillModel();
    this.submitDone = true;
    this.workIsSave = false;
    this.worksService.saveJobsAsked(wksWorkCard, 'appointment')
    .subscribe(
      (data: any) => {
        this.workIsSave = true;
        const wksWorkCardTmp = data.body;
        const titleBox = this.translate.instant('APPOINTMENT_DETAIL.TITLE_BOX_APP');
        const messageBox = this.translate.instant('APPOINTMENT_DETAIL.APPOINTMENT_VALID', { workRef: wksWorkCardTmp.workRef });
        this.displayMessageBox(titleBox, messageBox, 'INFO', 'infoWks', 'htmlMessage', undefined, undefined);
      },
      () => {
        this.workIsSave = true;
        const titleBox = this.translate.instant('APPOINTMENT_DETAIL.TITLE_BOX_APP');
        const messageBox = this.translate.instant('APPOINTMENT_DETAIL.APPOINTMENT_NOT_VALID');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'htmlMessage', undefined, undefined);
      }
    );
  }
  fillModel(): WksWorksModel {
    if (this.wksWorksTracking === undefined) {
      this.wksWorksTracking = [];
    }

    this.wksWorksTracking.push({
      numberTrack: 0,
      userLogged: this.authService.getUserLogged().username,
      recordedTrack: undefined,
      workStatut: 'appointment',
      workComment: undefined,
      alarmsMes: undefined
      }
    );
    /*
    let  workObjectLabel = '';
    if (this.askedService.type === 'OPHER') {
      workObjectLabel = this.askedService.label;
    } else {
      workObjectLabel = this.translate.instant('APPOINTMENT_DETAIL.' + this.askedService.type);
      if (this.askedService.hours !== undefined && this.askedService.hours > 0) {
        workObjectLabel += ' ' + this.askedService.hours + ' ' + this.translate.instant('APPOINTMENT_DETAIL.HOURS_SERVICE');
      }
    }
    */
    const  workObjectLabel = this.translate.instant('APPOINTMENT_DETAIL.JOB.WORK_REQUEST');
    const wksWorkCard: WksWorksModel = {
      id: (this.wksWorks && this.wksWorks.id) ? this.wksWorks.id : undefined,
      stdEntity: this.authService.getUserLogged().entity,
      workEquip: (this.workEquipmentId ? JSON.stringify(this.workEquipmentId) : undefined),
      ownerId: this.owner.id,
      workIdPrevious: undefined,
      workObject: workObjectLabel,
      workStatut: 'appointment',
      workTicket:  undefined,
      workCriticality: undefined,
      workPlanningId: undefined,
      workCustomer: this.owner,
      workComment: undefined,
      workLocation: undefined,
      workLocationData: undefined,

      workPosition: undefined,
      workJsonQuotation: undefined,
      workStatutDate: undefined,
      workRecordedDate: undefined,
      workTasksContent: undefined,
      wksWorksTracking: this.wksWorksTracking,
      allAvailable: undefined,
      statutChange: true,
      wksCalendar: undefined,
      wksMainEquipment: this.workEquipments.mainEquipment,
      wksSubEquipments: this.workEquipments.subEquipments,
      wksjobsAsked: (this.jobsAsked ? this.jobsAsked : undefined)
    };
    return wksWorkCard;
  }
  /*
  fillRegularService(): void {
    let positionsMotorList: string[];
    if (this.workEquipments.mainEquipment.equipBoatConfiguration !== 'single installation') {
      const motorsConfigList = this.sharingDataService.getMotorsconfigList();
      for (const configMotors of motorsConfigList) {
        if (configMotors.configName === this.workEquipments.mainEquipment.equipBoatConfiguration) {
          positionsMotorList = configMotors.positions;
          break;
        }
      }
    }
    if (this.workEquipments.subEquipments.length < this.numberMotors) {
      if (this.workEquipments.subEquipments[0].equipHours === undefined) {
        this.workEquipments.subEquipments[0].equipHours = -1;
      }
      if (this.workEquipments.subEquipments[0].equipModelInput !== undefined) {
        const addInfo = {
            equipModelInput: this.workEquipments.subEquipments[0].equipModelInput
          };

        this.workEquipments.subEquipments[0].equipAddInfo = JSON.stringify(addInfo);
      }
      this.workEquipments.subEquipments[0].equipDesign = this.translate.instant('WORK_EQUIPMENT.' + positionsMotorList[0]);
      this.workEquipments.subEquipments[0].equipName = this.workEquipments.subEquipments[0].equipDesign;
      this.workEquipments.subEquipments[0].equipJson = this.getEquipJson(positionsMotorList[0]);
      const equipCur = this.workEquipments.subEquipments[0];
      const exist = this.workEquipments.subEquipments.length;
      for (let i = exist ; i < this.numberMotors; i++ ) {
        this.workEquipments.subEquipments[i] = JSON.parse(JSON.stringify(equipCur));
        this.workEquipments.subEquipments[i].equipDesign = this.translate.instant('WORK_EQUIPMENT.' + positionsMotorList[i]);
        this.workEquipments.subEquipments[i].equipName = this.workEquipments.subEquipments[i].equipDesign;
        this.workEquipments.subEquipments[i].equipJson = this.getEquipJson(positionsMotorList[i]);
      }
    } else  {
      this.workEquipments.subEquipments[0].equipDesign = this.workEquipments.subEquipments[0].equipBrand
                                              + ' ' + this.workEquipments.subEquipments[0].equipModel;
      this.workEquipments.subEquipments[0].equipJson = this.getEquipJson('');
    }
  }
  updateSubEquip(equipSub: WksEquipmentModel ): void {
    if (this.workEquipments.subEquipments === undefined) {
      this.workEquipments.subEquipments = [];
    }
    equipSub.equipPartOf = (this.workEquipments.mainEquipment.id ? this.workEquipments.mainEquipment.id : undefined);
    equipSub.ownerId = this.workEquipments.mainEquipment.ownerId;
    equipSub.equipCountry = this.workEquipments.mainEquipment.equipCountry;
    if (this.subEquipmentNew) {
      // equipSub.equipNumber = this.workEquipments.subEquipments.length;
      // this.workEquipments.subEquipments.push(equipSub);
      if (equipSub.equipHours === 0 ) {
        equipSub.equipHours = -1;
      }
      this.workEquipments.subEquipments[0] = equipSub;
    } else {
      this.workEquipments.subEquipments[equipSub.equipNumber] = equipSub;
    }

  }
    */
  getEquipJson(positionsMotor: string): string  {
    let equipJsonLocal: string;
    let motorConfig = {};
    let motorJson: string;
    // const trailerJson = '{ "trailerJson": ""}';
    motorConfig = {
        thrusterOption: false,
        motorPosition: positionsMotor,
        equipBoatConfiguration: this.workEquipments.mainEquipment.equipBoatPropulsion,
      };
    motorJson = '{ "motorJson": ' + JSON.stringify(motorConfig) + '}';

    equipJsonLocal = '{ "jsonEquip": ';
    equipJsonLocal += motorJson;
    // equipJsonLocal += ',' + trailerJson;
    equipJsonLocal += '}';

    return equipJsonLocal;
  }
  returnHome(): void {
    this.okToDisplay = false;
    this.isWaiting = false;
    this.returnData.emit('exitAppointmentDetail');
  }
  // CUSTOMER
  buildCustomerBlock(): void {

    this.configAddress = {
      langCur: this.translate.getBrowserLang,
      countryDefault: this.authService.getUserLogged().entitycountry,
      address: true,
      telephone: false,
      email: false,
      contact: false,
      corporate: true,
      identifier: true,
      isReadOnly: true,
      fieldsId: []
    };
    this.isProfessionnal = false;
    if ((this.owner.bsnType === 'company') || (this.owner.bsnType === 'administration')) {
      this.configAddress.address = true;
      this.configAddress.telephone = false;
      this.configAddress.contact = false;
      this.configAddress.corporate = true;
      this.configAddress.email = false;
      this.configAddress.identifier = 'professional';
      this.addressCur = JSON.parse(this.owner.bsnCompany);
      this.isProfessionnal = true;
    } else {
      if (this.owner.bsnTax === 'professional') {
        this.isProfessionnal = true;
      }
      this.configAddress.address = true;
      this.configAddress.telephone = true;
      this.configAddress.contact = true;
      this.configAddress.corporate = false;
      this.configAddress.email = true;
      this.configAddress.identifier = this.owner.bsnTax;
      this.addressCur = JSON.parse(this.owner.bsnPerson);
      if (this.owner.dataContacts !== undefined) {
       // : ContactInt[]
       const listContacts =  this.owner.dataContacts;
       if (listContacts !== undefined && listContacts !== null) {
         const contactCur = listContacts[0];
         this.addressCur.emails = JSON.parse(contactCur.cntMails);
         this.addressCur.phones = JSON.parse(contactCur.cntPhones);
       }
      }
    }

  }
  // equipment choice
  buildEquipmentChoiceBlock(): void {
    this.equipmentChoiceForm = this.formBuilder.group({
      /* equipType: ['', Validators.required],*/
      workBoat: ['', Validators.required],
    },
    { updateOn: 'blur'});

  }
    // CUSTOMER
  buildServiceBlock(): void {
    this.serviceForm = this.formBuilder.group({
      choiceService: ['', Validators.required],
      askedService: [''],
      askedServiceHours: [''],
    },
    { updateOn: 'blur'});

  }
  readCustomer(): any {
    const userLogged = this.sharingDataService.getUserLogged();
    if (userLogged === undefined || userLogged.userdata === undefined) {
      const titleBox = this.translate.instant('APPOINTMENT_DETAIL.TITLE_BOX');
      const messageBox = this.translate.instant('APPOINTMENT_DETAIL.UUID_UNDEFINED',
                  {username: this.sharingDataService.getUserLogged().username,
                    nbError: '1' });
      this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', 'htmlMessage', undefined, undefined);
    }
    const userDataJson = JSON.parse(userLogged.userdata);

    if (userDataJson.contactUUID === undefined ) {
      const titleBox = this.translate.instant('APPOINTMENT_DETAIL.TITLE_BOX');
      const messageBox = this.translate.instant('APPOINTMENT_DETAIL.UUID_UNDEFINED',
                  {username: this.sharingDataService.getUserLogged().username,
                    nbError: '2' });
      this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', 'htmlMessage', undefined, undefined);
    }
    this.getCustomer(userDataJson.contactUUID, userLogged.entity)
    .then ((data: WksBusinessModel) => {
      this.customerName = data.bsnName;
      this.owner = data;
      this.buildCustomerBlock();
      this.wksWorks = undefined;
      this.wksWorks = {
        stdEntity: this.authService.getUserLogged().entity,
        ownerId: this.owner.id,
      };
      this.workEquipmentId = undefined;
      this.workEquipments = undefined;
      this.wksWorkIntervention = undefined;
      this.equipmentChoiceDisplay = true;
      this.okToDisplay = true;
      this.isWaiting = false;
      this.mainItemType = 'boats';
    }) // customer
    .catch((error: any) => {
      const titleBox = this.translate.instant('APPOINTMENT_DETAIL.TITLE_BOX');
      const messageBox = this.translate.instant('ADDRESS_BLOCK.IDENTIFIER_MESSAGE_BOX_2',
                                    {identifierCur: this.sharingDataService.getUserLogged().username });
      this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', 'htmlMessage', undefined, undefined);
    });
  }
  getCustomer(contactUUID: string, entity: string): any {

    return new Promise((resolve, reject) => {
      this.worksService.getCustomerByContact(contactUUID, entity)
      .subscribe(
        data => {
          resolve(data.body);
        },
        err => {
          // console.log('verifAccount' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  // EQUIPMENT
  resetEquipmentChoice(): void {
    // choice equipment
    this.equipmentChoiceDisplay = true;
    this.attachedEquipement = false;
    // work equipment
    this.workEquipmentsLoading = false;
    this.workEquipmentsLoaded = false;
    // main equipemnt
    this.mainEquipmentsLoaded = false;
    this.mainEquipmentsLoading = false;
    this.mainEquipmentDisplay = false;
    this.mainEquipmentNew = false;
    this.addFirstEquipment = false;
    this.isMainWorkEquip = false;
    this.mainEquipmentCur = undefined;
    // sub equipment
    this.subEquipmentsLoading = false;
    this.subEquipmentsLoaded = false;
    this.subEquipmentDisplay = false;
    this.subEquipmentNew = false;
    this.subEquipmentsCur = [];
    this.numberSubEquipementsDisplay = false;
    // boat : main equipement for sub equipement
    this.changeBoat = false;
    this.choiceBoatDisplay = false;
    this.loadBoats = false;
   }
/**
 * @deprecated this methiod is deprecated : choice of equipment not used
 */

  setIndexStepper(event: any): void {
    this.selectedIndexStepper = event.selectedIndex;
    if (this.selectedIndexStepper === 0) {
      this.workEquipments = undefined;
      this.loadBoatsList(this.mainItemType, true);
    }
    if (this.selectedIndexStepper === 1) {
      this.loadBoatsList(this.mainItemType, false);
    }
  }
  /** activate on every click on stepper content */
  triggerClickStepper(): void {
    // console.log(`Selected tab index: ${this.selectedIndexStepper}`);

  }
  onChangeworkBoat(event: WksEquipmentModel): void {
    this.mainEquipmentCur = event;

    this.isBoatEquip = false;
    this.mainEquipmentsLoading = false;
    // this.mainEquipmentsLoaded = false;
    this.changeBoat = false;
    this.mainEquipmentNew = false;
    this.mainEquipmentDisplay = true;
    this.mainItemType = 'boats';
    this.isMainWorkEquip = true;

    // this.equipmentChoiceDisplay = false;

    this.parentParam = {
      equipPartOf: this.mainEquipmentCur.id,
      ownerId: this.mainEquipmentCur.ownerId,
      equipType: '',
      equipBoatPropulsion: this.mainEquipmentCur.equipBoatPropulsion,
      equipBoatConfiguration: this.mainEquipmentCur.equipBoatConfiguration,
      equipBoatManeuvring: this.mainEquipmentCur.equipBoatManeuvring,
      commissioningDate: this.mainEquipmentCur.commissioningDate,
    };
    this.mainEquipmentCur.isMainEquip = true;
    this.mainEquipmentCur.isWorkEquip = true;
    /*
    if (this.equipTypeSelected.equipType !== this.mainEquipmentCur.equipType) {
      this.mainEquipmentCur.isWorkEquip = false;
    }*/
    if (this.workEquipments === undefined)  {
      this.workEquipments =  {
        mainEquipment: undefined,
        subEquipments: undefined,
      };
    }
    this.workEquipments.mainEquipment = this.mainEquipmentCur;
    /*if (this.equipTypeSelected.equipAttachedBoat) {
      this.loadSubEquipements();
    }*/

    this.isEquipmentCompleted = true;
  }
  loadBoatsList(mainType: string, isInit: boolean): void {
    if (isInit) {
      this.workEquipments = undefined;
      this.mainEquipmentCur = undefined;
    }
    this.mainEquipmentsLoading = true;
    this.mainEquipmentNew = false;
    this.addFirstEquipment = false;
    this.mainEquipmentDisplay = false;
    this.subEquipmentDisplay = false;
    this.boatsList = [];
    this.getWksEquipmentListOwner(mainType)
    .then ((response: any) => {
      this.boatsList = response  as WksEquipmentModel[];

      if (this.workEquipments !== undefined && this.workEquipments.mainEquipment !== undefined) {
        this.mainEquipmentCur = this.workEquipments.mainEquipment;
        this.mainEquipmentDisplay = true;
        // this.equipmentChoiceForm.controls.workBoat.setValue(this.mainEquipmentCur);
        this.equipmentChoiceForm.setValue({
          workBoat: this.mainEquipmentCur
        });
      }
      this.mainEquipmentsLoaded = true;
      this.mainEquipmentsLoading = false;
      this.choiceBoatDisplay = true;

    })
    .catch ((error: number) => {
      if (error === 404) {
        const titleBox = this.translate.instant('APPOINTMENT_DETAIL.TITLE_EQUIPMENT_BOX');
        // equipType
        const mainTypeLabel = this.translate.instant('APPOINTMENT_DETAIL.' + mainType);
        const messageBox = this.translate.instant('APPOINTMENT_DETAIL.BOAT_NOT_FOUND' , {equipType: mainTypeLabel });
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'noBoatFound', undefined, undefined );
      }
      return;
    });

  }
  getWksEquipmentListOwner(typeEquip: string): any {

    return new Promise((resolve, reject) => {
      this.worksService.getWksEquipmentListOwner(this.entityCur, this.owner.id, typeEquip)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  numberMotorsInput(nb: any): void {
    this.numberMotors = Number(nb);
    if (nb > 0) {
      this.subEquipmentDisplay = true;
      if (this.subEquipmentsCur.length === 0) {
        this.subEquipmentNew = true;
      }
    } else {
      this.subEquipmentDisplay = false;
      this.subEquipmentNew = false;
    }
  }

  getWksEquipmentByEquipId(equipId: string): any {

    return new Promise((resolve, reject) => {
      this.worksService.getWksEquipmentByEquipId(this.entityCur, equipId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  addMainEquipment(): void {
    this.mainEquipmentNew = true;

    this.workEquipments = undefined;
    this.mainEquipmentCur = undefined;
    this.subEquipmentsCur = undefined;

    this.mainEquipmentDisplay = true;
    this.subEquipmentDisplay = false;
    this.isMainWorkEquip = true;
    this.mainItemType = 'boats';
    this.isEquipmentCompleted = false;
    /*
    this.mainItemType = this.equipTypeSelected.equipType;
    if (this.equipTypeSelected.equipAttachedBoat) {
      this.isMainWorkEquip = false;
      this.mainItemType = 'boats';
    }
      */
    this.parentParam = {
      equipPartOf: undefined,
      ownerId: this.owner.id,
      equipType: this.mainItemType,
      equipBoatPropulsion: undefined,
      equipBoatConfiguration: undefined,
      equipBoatManeuvring: undefined,
      commissioningDate: undefined,
    };
  }

  updateMainEquip(equipMain: WksEquipmentModel ): void {
    if (this.workEquipments === undefined)  {
      this.workEquipments =  {
        mainEquipment: undefined,
        subEquipments: undefined,
      };
    }
    this.workEquipments.mainEquipment = equipMain;
    this.workEquipments.mainEquipment.equipType = this.mainItemType;
    this.workEquipments.mainEquipment.ownerId = this.owner.id;
    this.workEquipments.mainEquipment.equipHours = -1;
    if (this.workEquipments.mainEquipment.motorConfig !== undefined) {
      this.numberMotors = this.workEquipments.mainEquipment.motorConfig.numberMotors;
    }

  }

  formJobInput(jobsAskedArg: JobsAsked): void {
    this.jobsAsked = jobsAskedArg;
  }
  formEquipInput(formStatut: any ): void {
    if (formStatut === 'mainEquipInputOK') {

      this.boatsList.push(this.workEquipments.mainEquipment);
      this.mainEquipmentCur = this.workEquipments.mainEquipment;
      this.equipmentChoiceForm.controls.workBoat.setValue(this.mainEquipmentCur);

      this.isEquipmentCompleted = true;
    }

    if (formStatut === 'subEquipInputOK') {
      this.isServiceCompleted = true;
    }
    if (formStatut === 'subEquipInputKO') {
      this.isServiceCompleted = false;
    }
  }
  displayMessageBox(titleBoxArg: any, messageBoxArg: any, messageTypeArg: string, typeDialogArg: string,
                    actionCurArg: string, data1Arg: string, data2Arg: string): void {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: titleBoxArg,
    typeDialog: typeDialogArg,
    panelClass: 'stdTheme',
    contentMessage: messageBoxArg,
    data1: data1Arg,
    data2: data2Arg,
    messageType: messageTypeArg,
    actionCur: actionCurArg
  };

  const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);

  dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (actionCurArg === 'htmlMessage' ) {
          this.returnHome();
        }
        if (actionCurArg === 'noBoatFound' ) {
          this.addFirstEquipment = true;
          this.mainEquipmentsLoaded = true;
          this.mainEquipmentsLoading = false;
          this.choiceBoatDisplay = true;
          this.boatsList = [];
        }
    });

  }
  dataOutAddress(event: any): void {

  }

}
