<div *ngIf="OKToDisplay" #refElement class="container-fluid stdTheme"
        [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px'}" >
    <div style="padding-right: 5px; padding-left: 5px;" >
        <fieldset class="fieldsetJobEquipment" >
            <form [formGroup]="jobsForm" id="jobsForm"  >
                <div #jobBlock  formArrayName="jobsListArray"  >
                    <div *ngFor= "let jobCur of jobsList; let rowJob=index; let isLast=last" >
                        <mat-card style="padding: 0px; margin: 0px">
                            <div *ngIf="rowJob > 0" class="lineWks" ></div>        
                            <mat-card-actions style="margin: 0px; padding: 0px; width: 100%">
                                <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px; height: 20px;">
                                    <div class="col-12 marginField"style="display: flex; justify-content: space-between;">
                                        <button mat-icon-button class="buttonTheme" 
                                            style="margin-left: 0px;margin-right: 0px;height:20px !important;width:20px !important;font-size:20px !important;" 
                                            (click)="addEmptyJobEquip(rowJob + 1)">
                                            <span class="fa fa-plus-square" style="font-size: 20px;margin-top: -20px" ></span>
                                        </button>

                                        <button mat-icon-button class="buttonTheme" 
                                            style="margin-left: 0px;margin-right: 0px;height:20px !important;width:20px !important;font-size:20px !important;" 
                                            (click)="removeJob(rowJob)">
                                            <span class="fa fa-trash" style="font-size: 20px;margin-top: -20px" ></span>
                                        </button>
                                    </div>
                                </div>
                            </mat-card-actions>
                      
                            <mat-card-content>
                                <div [formGroupName]="rowJob" style = "width: 100%">
                                    <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px;">
                                        <div class="col-lg-3 col-xs-12 marginField">
                                            <mat-form-field class="wksInput form-group" appearance="standard">
                                                <mat-label>{{  'APPOINTMENT_DETAIL.JOB.EQUIPMENT.EQUIP_TYPE' | translate }}</mat-label>
                                                <mat-select id="equipType" placeholder="{{ 'APPOINTMENT_DETAIL.JOB.EQUIPMENT.EQUIP_TYPE' | translate }}"
                                                    panelClass="mdiselectcontent" #equipType ngDefaultControl
                                                    formControlName="equipType"
                                                    (selectionChange)="onChange('equipType', rowJob, equipType.value)" >
                                                    <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of listEquipType"
                                                        [value]="equipTypeCur">{{equipTypeCur.localLabel}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="!OkDisplaySubEquipemntsList[rowJob]" style = "width: 100%">
                                        <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px;">
                                            <div  class="col-lg-3 col-xs-12 marginField">
                                                <mat-form-field class="wksInput form-group" appearance="standard">
                                                    <mat-label>{{  'APPOINTMENT_DETAIL.JOB.EQUIPMENT.EQUIP_NAME' | translate }}</mat-label>
                                                    <input id="equipName" class="form-control inputTheme"  formControlName="equipName" 
                                                            matInput #equipName placeholder="{{ 'APPOINTMENT_DETAIL.JOB.EQUIPMENT.EQUIP_NAME' | translate }}" 
                                                            (change)="onChange('equipName', rowJob, equipName.value)" required>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="boatComponentsDesc[rowJob] !== undefined" style = "width: 100%">
                                                <div  *ngIf="boatComponentsDesc[rowJob].brand" class="col-lg-3 col-xs-12 marginField">
                                                    <mat-form-field class="wksInput form-group" appearance="standard">
                                                        <mat-label>{{ 'WORK_EQUIPMENT.equipBrand' | translate }}</mat-label>
                                                        <input id="equipBrand" class="form-control inputTheme"  formControlName="equipName" 
                                                                matInput #equipBrand placeholder="{{ 'WORK_EQUIPMENT.equipBrand' | translate }}" 
                                                                (change)="onChange('equipBrand', rowJob, equipBrand.value)" required>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="boatComponentsDesc[rowJob].model" class="col-lg-3 col-xs-12 marginField">
                                                    <mat-form-field class="wksInput form-group" appearance="standard">
                                                        <mat-label>{{ 'WORK_EQUIPMENT.equipModel' | translate }}</mat-label>
                                                        <input id="equipModel" class="form-control inputTheme"  formControlName="equipName" 
                                                                matInput #equipModel placeholder="{{ 'WORK_EQUIPMENT.equipModel' | translate }}" 
                                                                (change)="onChange('equipModel', rowJob, equipModel.value)" required>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="OkDisplaySubEquipemntsList[rowJob]" style = "width: 100%">
                                        <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px;">
                                            <div  class="col-lg-3 col-xs-12 marginField">
                                                <mat-form-field class="wksInput form-group" appearance="standard">
                                                    <mat-label>{{  'APPOINTMENT_DETAIL.JOB.EQUIPMENT.EQUIP_NAME' | translate }}</mat-label>
                                                    <mat-select id="equipName" placeholder="{{ 'APPOINTMENT_DETAIL.JOB.EQUIPMENT.EQUIP_NAME' | translate }}"
                                                        panelClass="mdiselectcontent" #equipName ngDefaultControl
                                                        formControlName="equipName"
                                                        (selectionChange)="onChange('subEquip', rowJob, equipName.value)" >
                                                        <mat-option class="selectOptionsTheme" *ngFor="let subEquipCur of subEquipmentsCur[rowJob]"
                                                            [value]="subEquipCur">{{subEquipCur.equipName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-row row stdTheme" style="margin-left: 0px; margin-right: 0px;">
                                        <div class="col-lg-5 col-xs-12 marginField marginJobField" >
                                            <mat-form-field class="wksInput form-group" appearance="standard" >
                                                <mat-label>{{  'APPOINTMENT_DETAIL.JOB.EQUIPMENT.REQUESTED_SERVICE' | translate }}</mat-label>
                                                <textarea  id="requestedService" class="form-control inputTheme textarea1" formControlName="requestedService" 
                                                    matInput #requestedService placeholder="{{ 'APPOINTMENT_DETAIL.JOB.EQUIPMENT.REQUESTED_SERVICE' | translate }}"
                                                    cdkTextareaAutosize required
                                                    #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="2"
                                                    cdkAutosizeMaxRows="4"
                                                    (keyup)="onChange('requestedService', rowJob, requestedService.value)"
                                                ></textarea >
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</div>

