import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/services/appointment.service';
import { BrandParam, CountryLabel, CustomParam, FormFieldFmt, LabelInt, LabelValue, MotorsConfigModel,
        PropulsionsModel, ThrusterConfigModel, TrailersOptions, WksEntityImg, WksEquipmentModel } from 'src/app/models';
import { BrandWksModel, TypesLabel } from 'src/app/models/works/wks-common.model';


import { AuthService } from 'src/app/services/auth.service';
import { SharingDataService } from 'src/app/services/sharing-data.service';
import { WorksService } from 'src/app/services/works.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-wks-appointment-equip',
  templateUrl: './wks-appointment-equip.component.html',
  styleUrls: ['./wks-appointment-equip.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WksAppointmentEquipComponent implements OnChanges {

  @Input() equipType: string;
  @Input() actionType: string;
  @Input() mainEquipmentArg: WksEquipmentModel;
  @Input() subEquipmentArg: WksEquipmentModel[];

  @Output() dataOutMain = new EventEmitter<WksEquipmentModel>();
  @Output() dataOutSub = new EventEmitter<WksEquipmentModel>();
  @Output() dataOutForm = new EventEmitter<string>();

  private readonly onDestroy = new Subject<void>();

  equipmentForm: FormGroup;
  isOkToDisplay: boolean;
  isBoat: boolean;
  isTrailer: boolean;
  isJet: boolean;
  isMainEquip: boolean;
  isReadOnly: boolean;
  isSingleMotor: boolean;
  isThusterMotor: boolean;
  isEngine: boolean;
  isMotorBoat: boolean;
  isMetric: boolean;
  isThrusterPossible: boolean;

  isEquipmentCollapse: boolean;
  entityCur: string;
  titleCard: string;
  mainEquipNameLegend: string;
  equipTypeList: TypesLabel[];
  brandFilteredList: BrandWksModel[];
  modelFilteredList: BrandWksModel[];
  countriesFilteredList: CountryLabel[];
  countriesCacheList: CountryLabel[];
  numberSubQuestion: boolean;
  subEquipTypeLegend: string;
  wksEntityImgCur: WksEntityImg;
  imgHelpLoaded: boolean;
  helpToShow: any;
  modelParam: CustomParam;
  serialNumberParam: CustomParam;
  serialNumberInput = {
    fmt: '',
    holder: '',
    display: '',
    dataLengthMax: 0,
    inputError: false,
    inputValue: '',
    separator: ''
  };

  ctrlInput: {
    multiEquipment: boolean;
    inputSerialNumber: boolean;
    ctrlSerialNumber: boolean;
    inputHours?: boolean;
    inputYear?: boolean;
  };
  hindusArabic: boolean;
  arabicsToLatin: LabelInt[];
  latinToArabics: LabelValue[];
  formField: string;
  listYears: any[];

  motorsConfigList: MotorsConfigModel[];
  propulsionsList: PropulsionsModel[];
  equipBoatManeuvringCheck: boolean;
  trailerOptions: TrailersOptions;
  hitchesList: LabelValue[];
  hitchSizes: string[];
  axlesList: string[];
  tiresList: string[];
  rimsList: string[];
  nutsList: string[];
  thrustersConfigList: ThrusterConfigModel[];
  positionsMotorList: string[];
  thrusterPossible: boolean;
  isBoatEquip: boolean;

  mainEquipment: WksEquipmentModel;
  subEquipment: WksEquipmentModel;

  constructor(private fb: FormBuilder,
              private sharingDataService: SharingDataService,
              private translateService: TranslateService,
              private appointmentService: AppointmentService,
              private worksService: WorksService,
              private authService: AuthService) { }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'equipType': {
            // tslint:disable-next-line:no-string-literal
            this.equipType = changes['equipType'].currentValue;
            break;
          }
          case 'actionType': {
            // tslint:disable-next-line:no-string-literal
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'mainEquipmentArg': {
            // tslint:disable-next-line:no-string-literal
            this.mainEquipmentArg = changes['mainEquipmentArg'].currentValue;
            break;
          }
          case 'subEquipmentArg': {
            // tslint:disable-next-line:no-string-literal
            this.subEquipmentArg = changes['subEquipmentArg'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }
  initData(): void {
    this.initAllBooleans();
    this.initVariables();
    this.initGlobalsParameters();
    this.loadBrands()
    .then ((brandList: any) => {
      this.buildFormEquipment();
      if (this.mainEquipmentArg !== undefined) {
        this.fillFormMainEquip();
      } else  {
        this.mainEquipment = undefined;
      }
      if (this.subEquipmentArg !== undefined) {
        this.fillFormSubEquip();
      } else {
        this.subEquipment = undefined;
      }
      this.isOkToDisplay = true;
    })
    .catch((error: any) => {
      console.log(error);

    });

  }
  initAllBooleans(): void {
    this.isReadOnly = false;
    this.isBoat = false;
    this.isMainEquip = false;
    this.isTrailer = false;
    this.isJet = false;
    this.isSingleMotor = false;
    this.isThusterMotor = false;
    this.isEngine = false;
    this.isMotorBoat = false;
    this.isMetric = false;
    this.isThrusterPossible = false;
    this.isOkToDisplay = false;
    this.isEquipmentCollapse = true;
    this.numberSubQuestion = false;
    this.hindusArabic = false;
    this.thrusterPossible = false;
    this.isBoatEquip = false;
  }
  initGlobalsParameters(): void {

    this.entityCur = this.authService.getUserLogged().entity;

    const listItem = this.sharingDataService.getComponentsList();
    this.ctrlInput = this.sharingDataService.getWksEntityParams().ctrlInput;
    this.ctrlInput.inputHours = true;
    this.ctrlInput.inputYear = true;
    this.listYears = this.initYearsList(50);

    this.equipTypeList = [];
    for (const item of listItem) {
      const itemCur: TypesLabel = {
        labelType: this.translateService.instant('APPOINTMENT_DETAIL.' + item),
        equipType: item,
        equipAttachedBoat: true
      };
      this.equipTypeList.push(itemCur);
    }
    const listEquip = this.sharingDataService.getEquipmentsList();
    for (const item of listEquip) {
      if (item.equipType === 'engine') {
        continue;
      }
      const itemCur: TypesLabel = {
        labelType: this.translateService.instant('APPOINTMENT_DETAIL.' + item.equipName),
        equipType: item.equipType,
        equipAttachedBoat: false
      };
      this.equipTypeList.push(itemCur);
    }
    this.equipTypeList.sort((obj1: TypesLabel, obj2: TypesLabel) => {
      return obj1.labelType > obj2.labelType ? 1 : -1;
    });

    this.loadCountries();
    this.settingHindusArabic();
  }
  initYearsList(minYear: number): string[] {
    const currentYear = new Date().getUTCFullYear();
    const years = [];
    let startYear = currentYear - minYear;
    while ( startYear <= currentYear ) {
        years.push((startYear++).toString());
    }

    // tslint:disable-next-line:only-arrow-functions
    years.sort(function(a: string, b: string): number {
      if (a > b) {
          return -1;
      }
      if (b > a) {
          return 1;
      }
      return 0;
    });
    years.splice(0, 0, '');
    return years;
  }
  loadTrailerOptions(): void {
    this.trailerOptions = this.sharingDataService.getTrailersOptions();
    // hitches
    this.hitchesList = [];
    this.hitchesList.push({
        label: '',
        value: ''
      });
    for (const hitch of this.trailerOptions.hitches) {
      const localItem: LabelValue = {
        label: this.translateService.instant('WORK_EQUIPMENT.' + hitch.name),
        value: hitch.name
      };
      this.hitchesList.push(localItem);
    }
    // axles
    this.axlesList = [' ', '1', '2', '3'];

    let measurementLocal = 'METRIC';
    if (!this.isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    // tires
    this.tiresList  = [' '];
    for (const measurLocal of this.trailerOptions.tires) {
      if (measurLocal.measurement === measurementLocal) {
        this.tiresList = measurLocal.list;
        this.tiresList.splice(0, 0, '');
        break;
      }
    }
    // rims
    this.rimsList  = [' '];
    for (const measurLocal of this.trailerOptions.rims) {
      if (measurLocal.measurement === measurementLocal) {
        this.rimsList = measurLocal.list;
        this.rimsList.splice(0, 0, '');
        break;
      }
    }
    // nuts
    this.nutsList = [' ', '1', '2', '3', '4', '5', '6'];
  }
  getHitchesSizes(hitchName: string, valueSelected: string): void {
    let measurementLocal = 'METRIC';
    if (!this.isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    this.hitchSizes = [];
    let isFound = false;
    for (const hitchCur of this.trailerOptions.hitches) {
      if (hitchCur.name === hitchName ) {
        for (const sizesCur of hitchCur.sizes) {
          if (sizesCur.measurement === measurementLocal) {
            this.hitchSizes = [...sizesCur.list];
            isFound = true;
            break;
          }
        }
      }
      if (isFound) {
        this.hitchSizes.splice(0, 0, '');
        break;
      }
    }
    if (valueSelected !== undefined) {
      this.equipmentForm.controls.equipHitchSize.setValue(valueSelected);
    }

  }
  settingHindusArabic(): void {
    this.hindusArabic = false;

    if (!this.translateService.currentLang.startsWith('ar')) {
      return;
    }
    const numbersFmt = this.sharingDataService.getCountryFmt().numbers;
    for (const numberItem of numbersFmt) {
      if (numberItem.name === 'hindusArabics') {
        this.hindusArabic = (numberItem.value === 'true' ? true : false);
        break;
      }
    }
    if (this.hindusArabic) {
      const unitsArabicsNumbers = environment.indusArabicsNumbers.units;
      this.arabicsToLatin = [];
      this.latinToArabics = [];
      for (const item of unitsArabicsNumbers) {
        const latin = item.latin;
        const hindus = item.hindus;
        this.arabicsToLatin.push({
          label: item.hindus,
          value: parseInt(item.latin, 10)
        });
        this.latinToArabics.push({
          label: item.latin,
          value: item.hindus
        });
      }
    }
  }
  loadBrands(): any {

    this.brandFilteredList = [];
    this.modelFilteredList = [];
    let brandList: BrandWksModel[];
    if (this.subEquipmentArg !== undefined) {
      this.equipType = this.subEquipmentArg[0].equipType;
    }
    return new Promise((resolve, reject) => {
      this.worksService.getWksBrandsList(this.authService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            for (const equipment of this.sharingDataService.getWksEntityParams().equipments) {
              if (equipment.type === this.equipType) {
                for (const brandItem of equipment.brands ) {
                  if (brandItem.label === brandCur.brandName) {
                    brandCur.brandParam = brandItem;
                    this.brandFilteredList.push(brandCur);
                  }
                }
              }
            }
          }
          this.brandFilteredList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandFilteredList.splice(0, 0,  {stdEntity: this.authService.getUserLogged().entity,
                                  brandRow: 'brand', brandName: '', brandParam: undefined} );

          resolve(brandList);
        }, err => {
          // console.log(err);
          if (err.status === 404) {
            this.brandFilteredList.push({
              stdEntity: this.authService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: '',
              brandParam: undefined
            });
          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any): void {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;

    for (const curBrand of this.brandFilteredList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        const brandCur = brandSelected.brandParam;
        this.serialNumberParam = brandCur.serialNumber;
        this.modelParam = brandCur.model;
        this.serialNumberInput.fmt = brandCur.serialNumber.fmt;
        this.serialNumberInput.holder = brandCur.serialNumber.holder;
        this.serialNumberInput.display = brandCur.serialNumber.display;
        this.serialNumberInput.inputError = false;
        this.serialNumberInput.dataLengthMax = brandCur.serialNumber.holder.length + 2;
        this.serialNumberInput.separator = brandCur.serialNumber.separator;
        const fmtLocal: FormFieldFmt = {
          pattern: brandCur.serialNumber.fmt,
          displayFmt: brandCur.serialNumber.display,
          displayUnfmt: '[ -]',
          fieldType: 'text',
          lenght: brandCur.serialNumber.lenght,
          separator: brandCur.serialNumber.separator,
          upperCase: brandCur.serialNumber.upperCase
        };
        this.formField = JSON.stringify(fmtLocal);
        break;
      }
    }
    this.updateData('equipBrand');
    this.initModelList(brandSelected);
  }
  onChangeModel($event: any): void {
    this.updateData('equipModel');
  }
  onChangeModelInput($event: any): void {
    this.updateData('equipModelInput');
  }
  initModelList(brandSelected: BrandWksModel): void {
    this.modelFilteredList = [];
    this.loadModels(brandSelected)
    .then ((modelList: any) => {
      if (this.mainEquipment !== undefined && this.mainEquipment.equipModel  !== undefined && this.mainEquipment.equipModel  !== null) {
        this.equipmentForm.controls.equipModel.setValue(this.mainEquipment.equipModel);
        if (this.equipmentForm.status === 'VALID') {
          this.dataOutForm.emit('mainEquipInputOK');
        }
      }
      if (this.subEquipment !== undefined
          && this.subEquipment.equipModel !== undefined
          && this.subEquipment.equipModel  !== null
          && this.subEquipment.equipHours !== null
          && this.subEquipment.equipHours > 0) {
        this.equipmentForm.controls.equipModel.setValue(this.subEquipment.equipModel);
        if (this.equipmentForm.status === 'VALID') {
          this.dataOutForm.emit('subEquipInputOK');
        }
      }
    })
    .catch((error: any) => {

    });
  }
  loadModels(brandSelected: BrandWksModel): any {
    let modelList: BrandWksModel[];
    this.modelFilteredList = [];
    const entityLocal = this.authService.getUserLogged().entity;
    return new Promise((resolve, reject) => {
      this.worksService.getWksModelsList(entityLocal, brandSelected.brandName, this.equipType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          modelList = data.body;
          for (const brandCur of modelList) {
            this.modelFilteredList.push(brandCur);
          }
          this.modelFilteredList.sort((obj1, obj2) => {
            return obj1.modelName > obj2.modelName ? 1 : -1;
          });
          this.modelFilteredList.splice(0, 0,  {
              stdEntity: this.authService.getUserLogged().entity,
              brandRow: 'model',
              brandParam: undefined,
              brandName: '',
              equipType: '',
              modelName: ''} );

          resolve(modelList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.modelFilteredList.push({
              stdEntity: this.authService.getUserLogged().entity,
              brandRow: 'model',
              brandParam: undefined,
              brandName: brandSelected.brandName,
              equipType: this.equipType,
              modelName: '',
            });

          }
          reject(err);
        }
      );
    });

  }
  onHelp(field: string): void {
    let imgName;
    this.imgHelpLoaded = false;
    switch (field) {
      case 'model': {
        imgName = this.modelParam.helpImg;
        break;
      }
      case 'serialNumber': {
        imgName = this.serialNumberParam.helpImg;
        break;
      }
    }
    if (imgName !== null) {
      this.loadHelpImg(imgName)
      .then ((data: WksEntityImg) => {
        this.wksEntityImgCur = data;
        this.createImageFromBlob(data);
        this.imgHelpLoaded = true;
      });
    }
  }
  loadHelpImg(path: string): any {
    const fileExtension = path.split('.').pop();
    const mimeApp =  this.appointmentService.getTypeMime(fileExtension);
    return new Promise((resolve, reject) => {
      this.appointmentService.downloadImg(path, this.entityCur, mimeApp, fileExtension)
      .subscribe(
        (retMes: any) => {
          const wksEntityImgCur = retMes.body;
          // const blob = new Blob([new Uint8Array(response.fileContent)], { type: mimeApp });
          const blob = new Blob([wksEntityImgCur.fileContent], { type: mimeApp });
          wksEntityImgCur.blobCur = blob;
          resolve(wksEntityImgCur);
        }, err => {
          reject(err);
        }
      );
    });
  }
  // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: WksEntityImg): void {
    // this.logoToShow = 'data:image/jpeg;base64,' + image.fileContent;
    this.helpToShow = 'data:' + image.fileMediaType + ';base64,' + image.fileContent;
  }
  closeHelp(): void {
    this.imgHelpLoaded = false;
  }
  onChangeCountry($event: any): void {
    this.updateData('equipCountry');
  }

  onKeyCountry($event: any): void {
    let valueArg;
    if ($event === '')  {
      valueArg = '';
    } else  {
      valueArg = ($event.target as HTMLInputElement).value;
    }
    this.countriesFilteredList = this.searchCountry(valueArg);
  }
  searchCountry(value: string): any {
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  loadCountries(): void {
    const countriesListTmp = this.sharingDataService.getCountriesList();

    this.countriesFilteredList = [];
    this.countriesCacheList = [];
    for (const countryTmp of countriesListTmp) {
      const labelLang = this.sharingDataService.getLabelCountry(this.translateService.currentLang.toUpperCase(), countryTmp.commonName);
      this.countriesCacheList.push({
        isoAlpha2: countryTmp.isoAlpha2,
        commonName: labelLang,
      });
    }

    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.commonName > obj2.commonName ? 1 : -1;
    });

    /*
    this.countriesCacheList.splice(0, 0, {
      isoAlpha2: '',
      commonName: '',
    });
    */
    this.countriesFilteredList = [...this.countriesCacheList];

  }
  initVariables(): void {
    if (this.actionType === 'add') {
      this.titleCard = 'New ';
      this.isEquipmentCollapse = false;
    }
    if ((this.actionType === 'update') || (this.actionType === 'display')) {
      this.titleCard = '';
      this.isEquipmentCollapse = false;
    } else if ((this.actionType === 'search') || (this.actionType === 'newsearch')) {
      this.titleCard = 'Searching ';
    }
    switch (this.equipType) {
      case 'boats': {
        this.isBoat = true;
        this.isMainEquip = true;
        this.titleCard += 'boat';
        this.isMotorBoat = true;
        break;
      }
      case 'jets': {
        this.isJet = true;
        this.isMainEquip = true;
        this.titleCard += 'jet';
        break;
      }
      case 'trailers': {
        this.isTrailer = true;
        this.isMainEquip = true;
        this.titleCard += 'trailer';
        break;
      }
      case 'engine': {
        this.isEngine = true;
        this.titleCard += 'engine';
        break;
      }
      case 'engines': {
        this.isEngine = true;
        this.titleCard += 'engine';
        break;
      }
      default:
        // this.titleCard = this.optionItem;
    }
    if ((this.isBoat) || (this.isEngine )) {
      this.isSingleMotor = true;
      this.motorsConfigList = [];
      this.propulsionsList = [];
      this.thrustersConfigList = [];
      this.motorsConfigList = this.sharingDataService.getMotorsconfigList();
      this.propulsionsList = this.sharingDataService.getPropulsionsList();
      this.thrustersConfigList = this.sharingDataService.getThrustersconfigList();
    }
  }

  formatField(typeRaw: string, paramsCur: any, inputValue: any): string {
    let valueReturn: any;
    let pattern: any;
    let displayFmt: string;
    let matchesGroup: any;
    let testMatch: any;
    switch (typeRaw) {
      case 'serialNumber': {
        const patternUnFmt = new RegExp(paramsCur.separator, 'g');
        const valueUnFmt = inputValue.replace(patternUnFmt, '');
        pattern = new RegExp(paramsCur.fmt, 'i');
        displayFmt = paramsCur.display;
        testMatch = valueUnFmt.match(pattern);
        if (testMatch === null) {
          this.serialNumberInput.inputError = true;
          // matchesGroup = inputValue.replace(pattern, displayFmt);
        } else {
          this.serialNumberInput.inputError = false;
          matchesGroup = inputValue.replace(pattern, displayFmt);
        }
        valueReturn = matchesGroup;
        this.serialNumberInput.inputValue = valueReturn;
        this.equipmentForm.controls.equipSN.patchValue(valueReturn, { emitEvent: true, onlySelf: false });
        break;
      }
      case 'equipHours' : {

        if (this.hindusArabic) {
          let valueLatin = '';
          const arrayChar = [...inputValue];
          for (const charCur of arrayChar) {
            for (const arabicItem of this.arabicsToLatin) {
              if (charCur === arabicItem.label) {
                valueLatin += arabicItem.value.toString();
                break;
              }
            }
          }
          if (isNaN( Number.parseInt(valueLatin, 10))) {
            this.equipmentForm.controls.equipHours.setValue('');

            valueReturn = '';
          } else {
            valueReturn = Number.parseInt(valueLatin, 10);
            this.equipmentForm.controls.equipHours.setValue(inputValue);
          }
        } else {
          if (isNaN( Number.parseInt(inputValue, 10))) {
            this.equipmentForm.controls.equipHours.setValue('0');
            valueReturn = '0';
          } else {
            valueReturn = Number.parseInt(inputValue, 10);
            this.equipmentForm.controls.equipHours.setValue(valueReturn);
          }
        }
        break;
      }
      default: {
        valueReturn = '';
        break;
      }
    }

    return valueReturn;
  }
  changeEquipBoatManeuvring($event: boolean): void {
    this.equipBoatManeuvringCheck = $event;
  }
  changeThrusterOption($event: boolean): void {
    this.isThusterMotor = $event;
    if (this.isThusterMotor) {
      this.isMotorBoat = false;
    } else {
      this.isMotorBoat = true;
    }

  }
  onChangePropulsion($event: any): void {
    for (const curProp of this.propulsionsList) {
      if (curProp.propulsionName === $event) {
        this.isMotorBoat = (curProp.propulsionType === 'motor');
        break;
      }
    }
    this.updateData('equipBoatPropulsion');
  }
  onChangeMotorConfig($event: any, selectedValue: string): void {
    if (this.isBoatEquip) {
      return;
    }
    if ($event === 'single installation') {
      this.isSingleMotor = true;
      this.updateData('equipBoatConfiguration');
    } else {
      this.isSingleMotor = false;
      for (const configMotors of this.motorsConfigList) {
        if (configMotors.configName === $event) {
          this.positionsMotorList = configMotors.positions;
          this.mainEquipment.motorConfig = configMotors;
          this.updateData('equipBoatConfiguration');
          break;
        }
      }
    }

  }

  buildFormEquipment(): void {
    this.mainEquipNameLegend = undefined;
    this.equipmentForm = this.fb.group({
      equipName: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipBrand: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipModel: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipModelInput: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipImmat: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipSN: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipCountry: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipHours: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipYear: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipBoatPropulsion: this.fb.control({value: '', disabled: this.isReadOnly}),
      equipBoatConfiguration: this.fb.control({value: '', disabled: this.isReadOnly}),
    });
  }
  fillFormMainEquip(): void {

    try  {
      this.mainEquipment = JSON.parse(JSON.stringify(this.mainEquipmentArg));
    } catch (err) {
      return;
    }

    this.equipmentForm.controls.equipName.setValue(this.mainEquipment.equipName);
    this.equipmentForm.controls.equipCountry.setValue(this.mainEquipment.equipCountry);
    this.equipmentForm.controls.equipBrand.setValue(this.mainEquipment.equipBrand);

    this.equipmentForm.controls.equipImmat.setValue(this.mainEquipment.equipImmat);
    this.equipmentForm.controls.equipSN.setValue(this.mainEquipment.equipSN);
    this.equipmentForm.controls.equipHours.setValue(this.mainEquipment.equipHours);
    this.equipmentForm.controls.equipYear.setValue(this.mainEquipment.equipYear);
    this.equipmentForm.controls.equipBoatPropulsion.setValue(this.mainEquipment.equipBoatPropulsion);
    this.equipmentForm.controls.equipBoatConfiguration.setValue(this.mainEquipment.equipBoatConfiguration);
    for (const curBrand of this.brandFilteredList) {
      if (curBrand.brandName === this.mainEquipment.equipBrand) {
        this.initModelList(curBrand);
      }
    }
  }
  fillFormSubEquip(): void {
    try  {
      this.subEquipment = JSON.parse(JSON.stringify(this.subEquipmentArg[0]));
    } catch (err) {
      return;
    }
    this.subEquipTypeLegend = this.translateService.instant('WORK_EQUIPMENT.' + this.subEquipmentArg[0].equipType);

    this.equipmentForm.controls.equipHours.setValue(this.subEquipment.equipHours);
    this.equipmentForm.controls.equipYear.setValue(this.subEquipment.equipYear);
    this.equipmentForm.controls.equipSN.setValue(this.subEquipment.equipSN);
    for (const curBrand of this.brandFilteredList) {
      if (curBrand.brandName === this.subEquipment.equipBrand) {
        this.initModelList(curBrand);
      }
    }
    this.equipmentForm.controls.equipBrand.setValue(this.subEquipment.equipBrand);
    this.equipmentForm.controls.equipModel.setValue(this.subEquipment.equipModel);
    this.equipmentForm.controls.equipModelInput.setValue(this.subEquipment.equipModel);
  }
  updateData(fieldName: string): void {

    const value = this.equipmentForm.controls[fieldName].value;
    if (this.isMainEquip) {
      this.mainEquipment = this.updateFied(fieldName, value, this.mainEquipment );
      let trailerValues = {};
      let trailerJson: string;
      if (this.isTrailer) {
        trailerValues = {
          equipHitch: this.equipmentForm.controls.equipHitch.value,
          equipHitchSize: this.equipmentForm.controls.equipHitchSize.value,
          equipAxles: this.equipmentForm.controls.equipAxles.value,
          equipTires: this.equipmentForm.controls.equipTires.value,
          equipRims: this.equipmentForm.controls.equipRims.value,
          equipNuts: this.equipmentForm.controls.equipNuts.value,
        };
        trailerJson = '{ "trailerJson": ' + JSON.stringify(trailerValues) + '}';
      }
      let equipJsonLocal: string;
      if ((trailerJson !== undefined)) {
        equipJsonLocal = '{ "jsonEquip": ';
        equipJsonLocal += trailerJson;
        equipJsonLocal += '}';
      }
      this.mainEquipment.equipJson = (equipJsonLocal ? equipJsonLocal : undefined);

      this.dataOutMain.emit(this.mainEquipment);
      if (this.equipmentForm.status === 'VALID') {
        this.dataOutForm.emit('mainEquipInputOK');
      }
    } else  {
      this.subEquipment = this.updateFied(fieldName, value, this.subEquipment );
      if (this.subEquipment.equipHours < 1) {
        this.dataOutForm.emit('subEquipInputKO');
        return;
      }
      this.dataOutSub.emit(this.subEquipment);
      if (this.equipmentForm.status === 'VALID') {
        this.dataOutForm.emit('subEquipInputOK');
      }
    }
  }
  updateFied(fieldName: string, value: any, wksEquipment: WksEquipmentModel): WksEquipmentModel {
    let wksEquipmentTmp: WksEquipmentModel;

    if (wksEquipment !== undefined) {
      wksEquipmentTmp = JSON.parse(JSON.stringify(wksEquipment));
    } else  {
      wksEquipmentTmp = {
        stdEntity: this.authService.getUserLogged().entity,
        isMainEquip: this.isMainEquip,
        isSubEquip: !this.isMainEquip,
        equipNumber: (this.isMainEquip ? -1 : 0)
      };
    }

    switch (fieldName) {
      case 'equipName': {
        wksEquipmentTmp.equipName = value;
        this.mainEquipNameLegend = value;
        if (value.length === 0 )  {
          this.mainEquipNameLegend = undefined;
        }
        break;
      }
      case 'equipImmat': {
        wksEquipmentTmp.equipImmat = value;
        break;
      }
      case 'equipBrand': {
        wksEquipmentTmp.equipBrand = value;
        break;
      }
      case 'equipModel': {
        wksEquipmentTmp.equipModel = value;
        break;
      }
      case 'equipModelInput': {
        wksEquipmentTmp.equipModelInput = value;
        break;
      }
      case 'equipSN': {
        wksEquipmentTmp.equipSN = value;
        break;
      }

      case 'equipCountry': {
        wksEquipmentTmp.equipCountry = value;
        break;
      }
      case 'equipHours': {
        wksEquipmentTmp.equipHours = value;
        break;
      }
      case 'equipYear': {
        wksEquipmentTmp.equipYear = value;
        break;
      }
      case 'equipBoatPropulsion': {
        wksEquipmentTmp.equipBoatPropulsion = value;
        break;
      }
      case 'equipBoatConfiguration': {
        wksEquipmentTmp.equipBoatConfiguration = value;
        break;
      }
      default:
        break;
    }
    return wksEquipmentTmp;
  }
}
